<template>
  <b-container fluid class="module-config-container m-0 px-2 px-md-3 pt-2 pt-md-3 d-flex flex-column">
    <div class="flex-grow-1 d-flex flex-column pb-2 pb-md-3 min-height-fit">
      <b-card no-body class="m-0 p-3 flex-grow-1 d-flex flex-column min-height-fit">
        <b-tabs justified>
          <b-tab title="Modules">
            <div class="flex-grow-1 d-flex flex-column">
              <module-list @view-datasheet="handleViewDatasheet" />
            </div>
          </b-tab>
          <b-tab title="Module Designs">
            <div class="flex-grow-1 d-flex flex-column">
              <module-type-list  @view-datasheet="handleViewDatasheet" />
            </div>
          </b-tab>
          <b-tab title="Module Groups">
            <div class="flex-grow-1 d-flex flex-column">
              <module-group-list />
            </div>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>

    <b-modal id="module-design-datasheet-modal" title="Module Design Datasheet" hide-footer>
      <module-design-datasheet modalId="module-design-datasheet-modal" :moduleDesign="datasheetModuleDesign" />
    </b-modal>
  </b-container>
</template>

<script>
import { BContainer, BCard, BTabs, BTab, BModal } from 'bootstrap-vue';

import ModuleList from '../components/SiteSettings/ModuleList.vue';
import ModuleTypeList from '../components/SiteSettings/ModuleTypeList.vue';
import ModuleGroupList from '../components/SiteSettings/ModuleGroupList.vue';
import ModuleDesignDatasheet from '../components/SiteSettings/ModuleDesignDatasheet.vue';

export default {
  name: 'ModuleConfig',
  components: {
    ModuleList,
    ModuleTypeList,
    ModuleGroupList,
    ModuleDesignDatasheet,
    BContainer,
    BCard,
    BTabs,
    BTab,
    BModal
  },
  data() {
    return {
      datasheetModuleDesign: null
    };
  },
  methods: {
    handleViewDatasheet(moduleDesign) {
      this.datasheetModuleDesign = moduleDesign;
      this.$bvModal.show('module-design-datasheet-modal');
    }
  }
};
</script>

<style scoped>
  .module-config-container {
    height: calc(100vh - 105px)
  }

  .min-height-fit {
    min-height: fit-content;
  }
</style>
