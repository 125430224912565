var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"display-4 sensor-link-header"},[_vm._v("Module Designs")]),_c('b-row',{staticClass:"my-3 m-0",attrs:{"no-gutters":"","align-h":"between","align-v":"center"}},[_c('b-col',{staticClass:"d-flex my-1",attrs:{"lg":"6"}},[_c('b-button',{staticClass:"flex-grow-1 flex-sm-grow-0",attrs:{"size":"sm","variant":"primary","disabled":_vm.requestInProgress || _vm.loadingSites},on:{"click":_vm.handleCreateClick}},[_vm._v("Create New Design")])],1),_c('b-col',{staticClass:"d-flex my-1",attrs:{"lg":"6"}},[_c('b-input-group',{attrs:{"size":"sm"}},[_c('b-form-input',{attrs:{"type":"search","placeholder":"Type to Search"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"disabled":!_vm.filter},on:{"click":function($event){_vm.filter = ''}}},[_vm._v("Clear")])],1)],1)],1)],1),_c('div',{staticClass:"d-flex flex-column justify-content-between flex-grow-1"},[_c('b-table',{attrs:{"id":"module-types-tables","items":_vm.moduleDesigns,"fields":_vm.tableFields,"primaryKey":"uuid","show-empty":!_vm.requestInProgress && !_vm.loadingSites,"busy":_vm.requestInProgress || _vm.loadingSites,"emptyText":"No module designs available","selectMode":"range","per-page":_vm.perPage,"current-page":_vm.currentPage,"hover":"","striped":"","sort-icon-left":"","responsive":"","stacked":"sm","filter":_vm.filter,"filter-function":_vm.filterMethod},on:{"filtered":_vm.onFiltered},scopedSlots:_vm._u([{key:"head(datasheet)",fn:function(scope){return [_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(scope.label))])]}},{key:"cell(datasheet)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"view-datasheet text-center",on:{"click":function($event){return _vm.handleViewDatasheet(Object.assign({}, item, {template: false}))}}},[_vm._v("View")])]}},{key:"cell(configure)",fn:function(ref){
var item = ref.item;
var toggleDetails = ref.toggleDetails;
return [_c('div',{staticClass:"d-inline-flex justify-content-end align-items-center w-100 pr-0 pr-sm-1 pr-md-2 pr-lg-3"},[_c('b-button',{staticClass:"mr-2",attrs:{"size":"sm","variant":"primary","aria-label":"configure module design"},on:{"click":function () { return _vm.handleEditClick(item); }}},[_c('font-awesome-icon',{attrs:{"icon":"wrench"}})],1),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",modifiers:{"hover":true,"top":true}}],staticClass:"mr-2",attrs:{"tabindex":"0","title":!Object.keys(item.attributes || {}).length ? 'There are no custom attributes for this design.' : ''}},[_c('b-button',{attrs:{"aria-label":"show custom attributes","size":"sm","variant":"info","disabled":!Object.keys(item.attributes || {}).length},on:{"click":toggleDetails}},[_c('font-awesome-icon',{attrs:{"icon":"info-circle"}})],1)],1),(_vm.canDelete)?_c('b-button',{attrs:{"aria-label":"delete module design","size":"sm","variant":"danger"},on:{"click":function () { return _vm.handleDeleteClick(item); }}},[_c('font-awesome-icon',{attrs:{"icon":"trash-alt"}})],1):_vm._e()],1)]}},{key:"row-details",fn:function(ref){
var item = ref.item;
return [_c('b-table',{attrs:{"items":_vm.mapDataToRow(item),"fields":['name', 'value'],"striped":"","outlined":"","responsive":"","stacked":"sm","small":"","hover":"","fixed":"","table-variant":"light","head-variant":"light"}})]}}])}),_c('div',{staticClass:"w-100 d-flex justify-content-center"},[(_vm.requestInProgress || _vm.loadingSites)?_c('msi-spinner',{staticClass:"no-select",attrs:{"size":60}}):_vm._e()],1),_c('div',{staticClass:"d-flex justify-content-center mt-2 w-100"},[_c('b-pagination',{staticClass:"m-0",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"aria-controls":"module-types-table"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1),_c('b-modal',{attrs:{"id":"module-design-modal","title":_vm.modalTitleText,"hide-footer":"","scrollable":""}},[_c('edit-module-type',{attrs:{"slot":"default","modalId":"module-design-modal","groupedTemplateModuleDesigns":_vm.groupedTemplateModuleDesigns,"moduleDesigns":_vm.moduleDesigns,"moduleDesign":_vm.editingDesign,"isEdit":_vm.isEdit,"existingAttributes":_vm.existingCustomAttributes},on:{"reload":_vm.reload,"view-datasheet":_vm.handleViewDatasheet},slot:"default"})],1),_c('b-modal',{ref:"deleteModal",attrs:{"title":"Delete Confirmation","ok-title":"Delete","ok-variant":"danger","ok-disabled":_vm.isDeleting,"size":"sm"},on:{"ok":function($event){$event.preventDefault();return _vm.executeDelete.apply(null, arguments)},"hidden":function($event){_vm.deleteModuleDesign = null}}},[(_vm.deleteModuleDesign)?_c('div',[_c('p',[_vm._v("Delete the module design "),_c('b',[_vm._v(_vm._s(_vm.deleteModuleDesign.name))]),_vm._v("?")]),_c('span',{staticStyle:{"color":"#ff6961"}},[_vm._v(" This action is "),_c('b',[_vm._v("not")]),_vm._v(" reversible. "),(_vm.isDeleting)?_c('msi-spinner',{attrs:{"size":28}}):_vm._e()],1)]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }