/* eslint-disable class-methods-use-this */
/* eslint-disable camelcase */
// eslint-disable-next-line max-classes-per-file
import et from 'elementtree';

class Point {
  constructor() {
    this.lat = 0;
    this.long = 0;
    this.elev = 0;
  }

  toWkt() {
    return `POINT(${this.long} ${this.lat})`;
  }
}

class Polygon {
  constructor() {
    this.points = [];
  }

  toWkt() {
    let wkt = 'POLYGON((';
    this.points.forEach((point, index) => {
      if (index > 0) {
        wkt += ', ';
      }
      wkt += `${point.long} ${point.lat}`;
    });
    wkt += '))';

    return wkt;
  }
}

class KmlNode {
  constructor(data) {
    if (typeof data === 'string') {
      this.root = et.parse(data).getroot();
    } else {
      this.root = data;
    }
  }

  write() {
    return (new et.ElementTree(this.root)).write({ xml_declaration: false });
  }

  getAllSubFolders() {
    if (!this.sub_folders) { this.sub_folders = this._getAllElements('Folder'); }

    return this.sub_folders;
  }

  getPlacemarks() {
    return this._getElements('Placemark');
  }

  findPlacemark(name) {
    const placemarks = this.root.findall('./Placemark');
    if (placemarks.length > 0) {
      const placemark = placemarks.find(e => e.findtext('name') === name);
      if (placemark) { return new KmlNode(placemark); }
    }
    return null;
  }

  addPlacemark(name, location) {
    const placemark = et.SubElement(this.root, 'Placemark');
    const name_tag = et.SubElement(placemark, 'name');
    const point_tag = et.SubElement(placemark, 'Point');
    const coords_tag = et.SubElement(point_tag, 'coordinates');
    name_tag.text = name;
    coords_tag.text = `${location.long},${location.lat},${location.elev}`;
  }

  get name() {
    if (!this._name) { this._name = this.root.findtext('./name'); }
    return this._name;
  }

  get description() {
    if (!this._description) { this._description = this.root.findtext('./description'); }
    return this._description;
  }

  createPoint(pointstr) {
    const coords = pointstr.split(',');
    const point = new Point();
    point.long = parseFloat(coords[0]);
    point.lat = parseFloat(coords[1]);
    point.elev = parseFloat(coords[2]);
    return point;
  }

  get isPoint() {
    return this.coordinates instanceof Point;
  }

  get isPolygon() {
    return this.coordinates instanceof Array;
  }

  get coordinates() {
    if (this._coordinates) return this._coordinates;

    const pointstr = this.root.findtext('./Point/coordinates');
    if (pointstr) {
      this._coordinates = this.createPoint(pointstr.trim());
      return this._coordinates;
    }

    const polygonstr = this.root.findtext('./Polygon/outerBoundaryIs/LinearRing/coordinates');
    if (polygonstr) {
      const point_strs = polygonstr.trim().split(' ');
      this._coordinates = new Polygon();
      point_strs.forEach((_pointstr) => {
        this._coordinates.points.push(this.createPoint(_pointstr));
      });
      return this._coordinates;
    }
    return null;
  }

  _getAllElements(type) {
    const kml_nodes = [];
    const elements = this.flatten(this.root, type);
    elements.forEach((element) => {
      kml_nodes.push(new KmlNode(element));
    });
    return kml_nodes;
  }

  _getElements(type) {
    const kml_nodes = [];
    const elements = this.root.findall(`./${type}`);
    elements.forEach((element) => {
      kml_nodes.push(new KmlNode(element));
    });
    return kml_nodes;
  }

  flatten(node, type) {
    let elements = node.findall(`./${type}`);
    node.getchildren().forEach((sub_node) => {
      elements = elements.concat(this.flatten(sub_node, type));
    });
    return elements;
  }

}

export default KmlNode;
