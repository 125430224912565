<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-form-group
          label-for="design-numberCellsSeries-input"
          label="Number of Cells (Series)"
          :invalid-feedback="validation.numberCellsSeries.message"
          :state="validation.numberCellsSeries.valid ? null : false">
          <b-form-input
            id="design-numberCellsSeries-input"
            :value="local.numberCellsSeries"
            placeholder="Number of Cells (Series)"
            type="number"
            @input="(val) => update('numberCellsSeries', transformNumber(val))"
            :disabled="disabled"
            :state="validation.numberCellsSeries.valid ? null : false" />
        </b-form-group>
      </b-col>
    </b-row>

    <fieldset class="p-2 form-col mb-3">
      <legend class="px-2">Nameplate</legend>
      <b-row>
        <b-col cols="12" sm="6">
          <b-form-group
            label-for="design-stcPower-input"
            label="STC Power">
            <b-input-group append="W">
              <b-form-input
                id="design-stcPower-input"
                :value="local.nameplateStcPower"
                placeholder="STC Power"
                type="number"
                @input="(val) => update('nameplateStcPower', transformNumber(val))"
                :disabled="disabled" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="6">
          <b-form-group
            label-for="design-Voc-input"
            label="Voc">
            <b-input-group append="V">
              <b-form-input
                id="design-Voc-input"
                :value="local.nameplateVoc"
                placeholder="Voc"
                type="number"
                @input="(val) => update('nameplateVoc', transformNumber(val))"
                :disabled="disabled" />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12" sm="6">
          <b-form-group
            label-for="design-Isc-input"
            label="Isc">
            <b-input-group append="A">
              <b-form-input
                id="design-Isc-input"
                :value="local.nameplateIsc"
                placeholder="Isc"
                type="number"
                @input="(val) => update('nameplateIsc', transformNumber(val))"
                :disabled="disabled" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="6">
          <b-form-group
            label-for="design-Vmp-input"
            label="Vmp">
            <b-input-group append="V">
              <b-form-input
                id="design-Vmp-input"
                :value="local.nameplateVmp"
                placeholder="Vmp"
                type="number"
                @input="(val) => update('nameplateVmp', transformNumber(val))"
                :disabled="disabled" />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12" sm="6">
          <b-form-group
            label-for="design-Imp-input"
            label="Imp">
            <b-input-group append="A">
              <b-form-input
                id="design-Imp-input"
                :value="local.nameplateImp"
                placeholder="Imp"
                type="number"
                @input="(val) => update('nameplateImp', transformNumber(val))"
                :disabled="disabled" />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </fieldset>

    <fieldset class="p-2 form-col mb-3">
      <legend class="px-2">Thermal Coefficients</legend>
      <b-row>
        <b-col cols="12" sm="6">
          <b-form-group
            label-for="design-tcPower-input"
            label="Power">
            <b-input-group append="%/°C">
              <b-form-input
                id="design-tcPower-input"
                :value="local.thermalCoefficientPower"
                placeholder="Power"
                type="number"
                @input="(val) => update('thermalCoefficientPower', transformNumber(val))"
                :disabled="disabled" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="6">
          <b-form-group
            label-for="design-tcVoltage-input"
            label="Voc">
            <b-input-group append="%/°C">
              <b-form-input
                id="design-tcVoltage-input"
                :value="local.thermalCoefficientVoltage"
                placeholder="Voc"
                type="number"
                @input="(val) => update('thermalCoefficientVoltage', transformNumber(val))"
                :disabled="disabled" />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12" sm="6">
          <b-form-group
            label-for="design-tcCurrent-input"
            label="Isc">
            <b-input-group append="%/°C">
              <b-form-input
                id="design-tcCurrent-input"
                :value="local.thermalCoefficientCurrent"
                placeholder="Isc"
                type="number"
                @input="(val) => update('thermalCoefficientCurrent', transformNumber(val))"
                :disabled="disabled" />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </fieldset>

    <fieldset class="p-2 form-col mb-3">
      <legend class="px-2 d-flex align-items-center">
        <div class="mr-1">Warranty</div>
        <info-icon tooltipClass="warranty-info-tooltip">
          <div class="pb-2">
            A list to specify the module design warranty. Each item in the list defines a <b>span</b> or warranty term
            and the warrantied performance at the <b>start</b> and <b>end</b> of the term.
          </div>
          <div>The inputs have the following format:</div>
          <div>
            <b>Span:</b> number of years for the warranty term since the last span or the warranty start date for the first item in the list.
          </div>
          <div>
            <b>Start/End:</b> performance as a percentage of the nameplate rating of the module design.
          </div>
        </info-icon>
      </legend>
      <warranty-form
        :value="local.warranty"
        @input="val => update('warranty', val)"
        @valid="val => this.warrantyValid = val" />
    </fieldset>
  </div>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormInput, BInputGroup } from 'bootstrap-vue';
import WarrantyForm from './WarrantyForm.vue';
import InfoIcon from '../../ui/InfoIcon.vue';
import { validateNumber, transformers } from './formUtils';

export default {
  name: 'RecommendedFields',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    WarrantyForm,
    InfoIcon
  },
  mixins: [transformers],
  props: {
    value: Object,
    disabled: Boolean
  },
  data() {
    return {
      warrantyValid: false
    };
  },
  computed: {
    local() {
      return this.value ? this.value : {};
    },
    validation() {
      return {
        numberCellsSeries: validateNumber(this.local.numberCellsSeries, 'Number of Cells (Series)', { integer: true, min: 0 })
      };
    },
    valid() {
      return !Object.values(this.validation).some(v => !v.valid) && this.warrantyValid;
    }
  },
  methods: {
    update(key, value) {
      this.$emit('input', { ...this.local, [key]: value });
    }
  },
  watch: {
    valid: {
      immediate: true,
      handler() {
        this.$emit('valid', this.valid);
      }
    }
  }
};
</script>

<style scoped>
fieldset.form-col {
  border: thin solid rgb(233, 236, 239);
  border-radius: 5px;
}

fieldset.form-col legend {
  font-size: 0.8em;
  padding-right: 1px;
  padding-left: 1px;
  width: auto;
  margin: 0;
  color: rgb(195, 195, 195);
}
</style>

<style>
.warranty-info-tooltip .tooltip-inner {
  text-align: left;
}
</style>
