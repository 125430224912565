<template>
<div>
  <h1 class="display-4 sensor-link-header">Solar Modules</h1>
  <b-row class="my-3 m-0" no-gutters align-h="between" align-v="center">
    <b-col lg="6" class="d-flex my-1">
      <b-button size="sm" variant="primary" class="mr-2" @click="$refs.file.click()"
      :disabled="requestInProgress || loadingSites">Load KML File</b-button>
      <input type="file" style="display: none;" ref="file" accept=".kml" @change="handleKmlUpload">
      <b-button size="sm" variant="primary" class="mr-2" @click="handleCreateClick"
      :disabled="requestInProgress || loadingSites">Add New Module</b-button>
      <b-form-checkbox v-model="showArchived" button class="archived-button" size="sm">
        {{showArchived ? 'Hide' : 'Show'}} Archived
      </b-form-checkbox>
    </b-col>
    <b-col lg="6" class="d-flex my-1">
      <b-input-group size="sm">
        <b-form-input
          v-model="filter"
          type="search"
          placeholder="Type to Search"
        ></b-form-input>
        <b-input-group-append>
          <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
        </b-input-group-append>
      </b-input-group>
    </b-col>
  </b-row>
  <div class="d-flex flex-column justify-content-between flex-grow-1">
    <b-table
      id="modules-tables"
      :items="solarModules"
      :fields="tableFields"
      primaryKey="uuid"
      :show-empty="!requestInProgress && !loadingSites"
      :busy="requestInProgress || loadingSites"
      emptyText="No solar modules available"
      selectMode="range"
      :per-page="perPage"
      :current-page="currentPage"
      :filter="filter"
      :filter-function="filterMethod"
      hover
      striped
      sort-icon-left
      responsive
      stacked="sm"
      @filtered="onFiltered"
    >
      <template v-slot:head(datasheet)="scope">
        <div class="text-center">{{ scope.label }}</div>
      </template>

      <template v-slot:cell(datasheet)="{ item }">
        <div class="view-datasheet text-center" @click="handleViewDatasheet(item.moduleDesign ? item.moduleDesign : null)">View</div>
      </template>

      <template v-slot:cell(coords)=" { item }">
        <div class="d-inline-flex w-100 align-items-center" v-if="!!item.coords">
          {{`(${item.coords.lat}, ${item.coords.lng})`}}
          <span v-if="supportsClipboard" v-b-tooltip.hover title="Copy Coordinates">
            <font-awesome-icon v-if="supportsClipboard" class="ml-2 button-icon" :icon="['far', 'copy']" @click="() => handleCopy(item.coords)"/>
          </span>
        </div>
      </template>
      <template v-slot:cell(isControlModule)="{ item }">
        <div class="d-flex justify-content-center" v-if="item.isControlModule">
          <span class="font-weight-bolder">&check;</span>
        </div>
      </template>
      <template v-slot:cell(configure)="{ item, toggleDetails }">
        <div class="d-inline-flex justify-content-end w-100 pr-0 pr-sm-1 pr-md-2 pr-lg-3">
          <b-button size="sm" variant="primary" class="mr-2" aria-label="configure module" @click="() => handleEditClick(item)"
            :disabled="requestInProgress || item.archived">
            <font-awesome-icon icon='wrench' />
          </b-button>
          <div
            class="mr-2"
            tabindex="0"
            v-b-tooltip.hover.top
            :title="!Object.keys(item.attributes || {}).length ? 'There are no custom attributes for this module.' : ''">
            <b-button aria-label="show custom attributes" size="sm" variant="info"
            @click="toggleDetails" :disabled="!Object.keys(item.attributes || {}).length">
              <font-awesome-icon icon='info-circle' />
            </b-button>
          </div>
          <b-button
            :aria-label="item.archived ? 'unarchive module' : 'archive module'"
            size="sm"
            :variant="item.archived ? 'success' : 'danger'"
            @click="() => handleArchive(item)"
            v-b-tooltip.hover
            :title="item.archived ? 'Restore' : 'Archive'">
            <font-awesome-icon icon="archive" />
          </b-button>
        </div>
      </template>
      <template v-slot:row-details="{ item }">
        <b-table
          :items="mapDataToRow(item)"
          :fields="['name', 'value']"
          striped
          outlined
          responsive
          stacked="sm"
          small
          hover
          fixed
          table-variant="light"
          head-variant="light"
        />
      </template>
    </b-table>
    <div class="w-100 d-flex justify-content-center">
      <msi-spinner class="no-select" :size="60" v-if="requestInProgress || loadingSites"/>
    </div>
    <div class="d-flex justify-content-center mt-2 w-100">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        aria-controls="modules-table"
        align="fill"
        :limit="$feature.mobile ? 5 : 8"
        class="m-0"
      ></b-pagination>
    </div>
  </div>

  <b-modal id="module-config-modal" :title="modalTitleText" hide-footer>
    <edit-module
      modalId="module-config-modal"
      :moduleDesigns="moduleDesigns"
      :groups="moduleGroups"
      :solarModule="editingSolarModule"
      :isEdit="isEdit"
      :existingAttributes="existingCustomAttributes"
      @reload="reloadSolarModules"
      @view-datasheet="handleViewDatasheet"
      slot="default"/>
  </b-modal>
</div>
</template>

<script>
import { get } from 'vuex-pathify';
import { BRow, BCol, BButton, BFormCheckbox, BInputGroup, BInputGroupAppend, BTable, BPagination, BModal, BFormInput } from 'bootstrap-vue';

import KmlParser from '../../helpers/kmlParser';
import EditModule from './EditModule.vue';
import MsiSpinner from '../MsiSpinner.vue';
import store from '../../store';

export default {
  name: 'ModuleList',
  components: {
    EditModule,
    MsiSpinner,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BTable,
    BPagination,
    BModal,
    BFormInput
  },
  data() {
    return {
      requestInProgress: false,
      editingSolarModule: null,
      isEdit: false,
      tableFields: [
        { key: 'name', sortable: true },
        { key: 'linkedDaq', label: 'DAQ ID', sortable: true },
        { key: 'serialNumber', sortable: true },
        { key: 'coords', label: 'Location' },
        { key: 'moduleDesign.name', label: 'Module Design', sortable: true },
        { key: 'group.name', label: 'Group', sortable: true },
        { key: 'isControlModule', label: 'Control Module' },
        { key: 'datasheet', label: 'Datasheet' },
        { key: 'configure', label: '' },
      ],
      perPage: 12,
      currentPage: 1,
      totalRows: 1,
      filter: '',
      supportsClipboard: false,
      showArchived: false
    };
  },
  computed: {
    selectedSite: get('sites/selectedSite'),
    getSiteSolarModules: get('solarmodules/getSiteSolarModules'),
    unfilteredModuleGroups: get('groups/getGroups'),
    getSiteModuleDesigns: get('moduleDesigns/getSiteModuleDesigns'),
    loadingSites: get('sites/loadingSites'),
    moduleDesigns() {
      if (!this.selectedSite) return [];
      return this.getSiteModuleDesigns(this.selectedSite.id);
    },
    moduleGroups() {
      if (!this.selectedSite) return [];
      return this.unfilteredModuleGroups.filter(group => group.siteCode === this.selectedSite.id);
    },
    solarModules() {
      if (!this.selectedSite || !this.moduleDesigns) return [];
      const solarModules = this.getSiteSolarModules(this.selectedSite.id, this.showArchived);
      return solarModules.map((sModule) => {
        const group = this.moduleGroups.find(gr => gr.uuid === sModule.groupUuid);
        return { ...sModule, group };
      });
    },
    modalTitleText() {
      if (this.isEdit) return 'Solar Module Configuration';
      return 'New Module';
    },
    existingCustomAttributes() {
      const existingAttributes = {};
      this.solarModules.forEach(({ attributes }) => {
        if (!attributes) return;
        Object.entries(attributes).forEach(([key, value]) => {
          if (!existingAttributes[key]) existingAttributes[key] = new Set();
          existingAttributes[key].add(value);
        });
      });
      return existingAttributes;
    }
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    handleEditClick(solarModule) {
      this.editingSolarModule = solarModule;
      this.isEdit = true;
      this.$bvModal.show('module-config-modal');
    },
    handleCreateClick() {
      this.editingSolarModule = { name: '' };
      this.isEdit = false;
      this.$bvModal.show('module-config-modal');
    },
    handleKmlUpload(e) {
      if (!e.target.files.length) {
        this.$toastWarn('Unsuccessful', 'No files were selected.');
        return;
      }
      const file = e.target.files[0];
      if (!file.name.match(/\.kml$/i)) {
        this.$toastError('Error', 'Only .kml files are supported.');
        return;
      }
      const reader = new FileReader();
      reader.onload = () => this.handleKmlFile(reader.result);
      reader.readAsText(file);
    },
    handleCopy(coords) {
      if (!this.supportsClipboard) return;
      navigator.clipboard.writeText(`(${coords.lat}, ${coords.lng})`)
        .then(() => this.$toastSuccess('Successful', 'Coordinates copied to clipboard'))
        .catch(() => this.$toastError('Error', 'Couldn\'t copy to clipboard'));
    },
    async handleKmlFile(xmlString) {
      this.requestInProgress = true;
      const kmlNode = new KmlParser(xmlString);
      const placemarks = kmlNode._getElements('.//Placemark');
      const modulesToUpdate = [];

      placemarks.forEach((placemark) => {
        const realModule = this.solarModules.find(sMod => sMod.serialNumber === placemark.name);
        if (!realModule) return;
        const { lat, long: lng } = placemark.coordinates;
        modulesToUpdate.push({ uuid: realModule.uuid, coords: { lat, lng } });
      });

      const siteCode = this.selectedSite.id;
      const requests = modulesToUpdate.map(m => this.$daqApi.put(`/sites/${siteCode}/modules/${m.uuid}`, { body: { coords: m.coords } }));
      const responses = await Promise.all(requests.map(request => request
        .then(value => ({ status: 'fulfilled', value }))
        .catch(() => ({ status: 'rejected' }))));

      if (responses.every(response => response.status === 'fulfilled')) {
        this.$toastSuccess('Successful', 'All modules were successfully updated.');
        this.reloadSolarModules();
      } else if (responses.some(response => response.status === 'fulfilled')) {
        this.$toastWarn('Partial Update Failure', 'Some modules failed to be updated.');
        this.reloadSolarModules();
      } else this.$toastError('Update Error', 'All modules failed to be updated.');

      this.requestInProgress = false;
    },
    async reloadSolarModules() {
      store.set('solarmodules/solarModules');
    },
    mapDataToRow(data) {
      return Object.entries(data.attributes).map(([name, value]) => ({ name, value }));
    },
    filterMethod(sModule, filterString) {
      const parts = filterString.split(/ +/g).map(s => s.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'));
      const regex = RegExp(parts.join('.*'), 'i');
      if (sModule.name.match(regex)) return true;
      if (sModule.serialNumber && sModule.serialNumber.match(regex)) return true;
      if (sModule.moduleDesign && sModule.moduleDesign.name && sModule.moduleDesign.name.match(regex)) return true;
      if (sModule.group && sModule.group.name.match(regex)) return true;
      return false;
    },
    async handleArchive(sModule) {
      if (!sModule || !sModule.uuid) return;
      this.requestInProgress = true;
      const endpoint = sModule.archived ? 'unarchive' : 'archive';
      try {
        const response = await this.$daqApi.put(`/sites/${this.selectedSite.id}/modules/${sModule.uuid}/${endpoint}`);
        this.$gtagPlugin.events.Module.archive({ event_label: sModule.uuid });
        this.$toastSuccess('Successful', response.message);
        this.reloadSolarModules();
      } catch (e) {
        if (e.name === 'ApiError') this.$toastError(`Error ${e.status || ''}`, e.message);
        else throw e;
      } finally {
        this.requestInProgress = false;
      }
    },
    handleViewDatasheet(moduleDesign) {
      if (moduleDesign) this.$emit('view-datasheet', moduleDesign);
    }
  },
  created() {
    if (navigator.clipboard) {
      this.supportsClipboard = true;
    }
  },
  watch: {
    solarModules: {
      immediate: true,
      handler(modules) {
        this.totalRows = modules.length;
      },
    }
  }
};
</script>

<style lang="scss" scoped>
  .sensor-config-container {
    height: calc(100vh - 105px)
  }

  .min-height-fit {
    min-height: fit-content;
  }

  .sensor-link-header {
    font-size: 1.8rem;
  }

  .button-icon {
    cursor: pointer;
    transition: all .1s ease-in-out;
  }

  .button-icon:hover {
    transform: scale(1.05);
  }

  .button-icon:active {
    transform: scale(0.9);
  }

  .view-datasheet {
    color: $msi-orange;
    cursor: pointer;
    font-weight: 500;
  }

  .view-datasheet:hover {
    color: $msi-orange-dark;
  }
</style>

<style lang="scss">
  .archived-button .btn.active {
    background-color: $msi-orange !important;
    color: white !important;
    border-color: $msi-orange !important;
  }
</style>
