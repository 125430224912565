<template>
  <div class="messages">
    <b-table
      :items="tableItems"
      :fields="['name', 'value', { key: 'remove', label: '' }]"
      striped
      outlined
      responsive
      stacked="sm"
      small
      show-empty
      :empty-text="emptyText"
    >
      <template v-slot:cell(value)="{ item }">
        <b-form-input size="sm" type="text" :value="item.value" @input="onValChange(item.name, $event)" :list="`edit-attr-value-list-${item.name}`"/>

        <datalist :id="`edit-attr-value-list-${item.name}`">
          <option v-for="value in getExistingValues(item.name)" :key="`kve-value-${item.name}-${value}`">{{value}}</option>
        </datalist>
      </template>
      <template v-slot:cell(remove)="{ item }">
        <div class="d-flex justify-content-center">
          <b-button aria-label="remove" variant="danger" size="sm"
              @click="onRemove(item.name)" :class="[isMobile ? 'mobile-button' : 'round-button']">
              <font-awesome-icon v-if="!isMobile" icon="times" />
              <p v-if="isMobile">Remove</p>
          </b-button>
        </div>
      </template>
    </b-table>
    <b-form-group label-for="new-attr-form" label="New Attribute">
      <b-row class="form-group" id="new-attr-form">
        <b-col cols="12" md="5" class="mt-2">
          <b-form-input size="sm" type="text" v-model="currentKey" placeholder="Attribute Name" list='new-attr-key-list' />

          <datalist id='new-attr-key-list'>
            <option v-for="key in existingKeys" :key="`kve-key-${key}`">{{key}}</option>
          </datalist>
        </b-col>
        <b-col cols="12" md=5 class="mt-2">
          <b-form-input
            size="sm"
            type="text"
            v-model="currentValue"
            placeholder="Attribute Value"
            @keyup.enter="addNewElement"
            list='new-attr-value-list'/>

          <datalist id='new-attr-value-list'>
            <option v-for="value in getExistingValues(currentKey)" :key="`kve-value-${currentKey}-${value}`">{{value}}</option>
          </datalist>
        </b-col>
        <b-col cols="12" md=2 class="mt-2">
          <b-button variant="primary"
            aria-label="add"
            size="sm"
            @click="addNewElement()"
            :disabled="invalidKey"
            :class="[isMobile ? 'mobile-button' : 'round-button']"
            >
            <font-awesome-icon v-if="!isMobile" icon="plus" />
            <p v-if="isMobile">Add</p>
          </b-button>
        </b-col>
      </b-row>
    </b-form-group>
  </div>
</template>

<script>
import { BTable, BFormInput, BButton, BFormGroup, BRow, BCol } from 'bootstrap-vue';

export default {
  components: {
    BTable,
    BFormInput,
    BButton,
    BFormGroup,
    BRow,
    BCol
  },
  props: {
    value: {
      type: Object,
    },
    existingOptions: {
      type: Object,
      default: () => ({}),
    },
    emptyText: {
      type: String,
      default: 'This module has no custom attributes. Add a new attribute below!'
    }
  },
  data(){
    return {
      counter: 1,
      currentKey: '',
      currentValue: '',
    };
  },
  computed: {
    existingKeys() {
      return Object.keys(this.existingOptions);
    },
    tableItems() {
      return Object.entries(this.value).map(([name, value]) => ({ name, value }));
    },
    isMobile() {
      return this.$feature.mobile;
    },
    invalidKey() {
      if (!this.currentKey.length) return true;
      return !!this.value[this.currentKey];
    }
  },
  methods: {
    getExistingValues(key) {
      if (!this.existingOptions[key]) return new Set();
      return this.existingOptions[key];
    },
    onValChange(key, newVal) {
      const clone = { ...this.value, [key]: newVal || '' };
      this.$emit('input', clone);
    },
    onRemove(key) {
      const clone = { ...this.value };
      delete clone[key];
      this.$emit('input', clone);
    },
    addNewElement() {
      if (!this.currentKey || this.invalidKey) return;
      this.$emit('input', { ...this.value, [this.currentKey]: this.currentValue });
      this.currentKey = '';
      this.currentValue = '';
    }
  }
};
</script>

<style>
  .round-button {
    padding: 0;
    width: 24px;
    height: 24px;
    border-radius: 12px;
  }

  .mobile-button {
    padding: 0;
    width: 100%;
    height: 24px;
    border-radius: 0.25rem;
  }
</style>
