<template>
  <div class="d-flex align-items-center">
    <font-awesome-icon
      class="info-icon"
      icon="info-circle"
      :size="size"
      ref="info-icon" />

    <b-tooltip
      :target="() => $refs['info-icon']"
      :placement="tooltipPlacement"
      :custom-class="tooltipClass">
      <slot>{{ tooltipContent }}</slot>
    </b-tooltip>
  </div>
</template>

<script>
import { BTooltip } from 'bootstrap-vue';

export default {
  name: 'InfoIcon',
  components: {
    BTooltip
  },
  props: {
    size: {
      type: String,
      default: 'lg'
    },
    tooltipContent: {
      type: String
    },
    tooltipPlacement: {
      type: String,
      default: 'right'
    },
    tooltipClass: {
      type: String
    }
  }
};
</script>

<style lang="scss" scoped>
.info-icon {
  cursor: pointer;
  color: #aaa;
}

.info-icon:hover {
  color: #888;
}
</style>
