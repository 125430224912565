<template>
  <div>
    <div class="pb-4">
      <div class="datasheet-title pb-2">{{ moduleDesignDatasheet | formatAttribute('name') }}</div>
      <b-row class="datasheet-info">
        <b-col cols="4" class="datasheet-subtitle">Manufactuer</b-col>
        <b-col cols="8">{{ moduleDesignDatasheet | formatAttribute('manufacturer') }}</b-col>
      </b-row>
      <b-row class="datasheet-info">
        <b-col cols="4" class="datasheet-subtitle">Model</b-col>
        <b-col cols="8">{{ moduleDesignDatasheet | formatAttribute('modelNumber') }}</b-col>
      </b-row>
      <b-row class="datasheet-info">
        <b-col cols="4" class="datasheet-subtitle">Description</b-col>
        <b-col cols="8">{{ moduleDesignDatasheet | formatAttribute('description') }}</b-col>
      </b-row>
      <b-row class="datasheet-info">
        <b-col cols="4" class="datasheet-subtitle">User Created</b-col>
        <b-col cols="8">{{ moduleDesignDatasheet | formatAttribute('template') }}</b-col>
      </b-row>
    </div>
    <b-tabs small justified>
      <b-tab title="Overview" active>
        <b-table-simple small hover striped class="mb-0 table-small-text">
          <b-thead>
            <b-tr>
              <b-th colspan="2">Physical Properties</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td>Family</b-td>
              <b-td class="text-center">{{ moduleDesignDatasheet | formatAttribute('family') }}</b-td>
            </b-tr>
            <b-tr>
              <b-td>Technology</b-td>
              <b-td class="text-center">{{ moduleDesignDatasheet | formatAttribute('technology') }}</b-td>
            </b-tr>
            <b-tr>
              <b-td>Area</b-td>
              <b-td class="text-center">{{ moduleDesignDatasheet | formatAttribute('area', 'm²') }}</b-td>
            </b-tr>
            <b-tr>
              <b-td>Dimensions (L x W)</b-td>
              <b-td class="text-center">
                {{ moduleDesignDatasheet | formatAttribute('length', 'm') }} x
                {{ moduleDesignDatasheet | formatAttribute('width', 'm') }}
              </b-td>
            </b-tr>
            <b-tr>
              <b-td>Number of Cells (Series)</b-td>
              <b-td class="text-center">{{ moduleDesignDatasheet | formatAttribute('numberCellsSeries') }}</b-td>
            </b-tr>
            <b-tr>
              <b-td>Number of Cells (Parallel)</b-td>
              <b-td class="text-center">{{ moduleDesignDatasheet | formatAttribute('numberCellsParallel') }}</b-td>
            </b-tr>
          </b-tbody>
          <b-thead>
            <b-tr>
              <b-th colspan="2">Warranty</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-if="!moduleDesignDatasheet.warranty || !moduleDesignDatasheet.warranty.length">
              <b-td class="text-center" colspan="2">No Warranty Information</b-td>
            </b-tr>
            <b-tr v-for="(warranty, index) in moduleDesignDatasheet.warranty" :key="index">
              <b-td v-if="index === 0">First {{ warranty.span }} years</b-td>
              <b-td v-else>Next {{ warranty.span }} years</b-td>
              <b-td class="text-center">{{ warranty.start }}% - {{ warranty.end }}%</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-tab>

      <b-tab title="Electrical">
        <b-table-simple small hover striped class="mb-0 table-small-text">
          <b-thead>
            <b-tr>
              <b-th colspan="2">STC</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td>Module Power (Pmax)</b-td>
              <b-td class="text-center">{{ moduleDesignDatasheet | formatAttribute('nameplateStcPower', 'Wp') }}</b-td>
            </b-tr>
            <b-tr>
              <b-td>Maximum power voltage (Vpmax)</b-td>
              <b-td class="text-center">{{ moduleDesignDatasheet | formatAttribute('nameplateVmp', 'V') }}</b-td>
            </b-tr>
            <b-tr>
              <b-td>Maximum power current (Ipmax)</b-td>
              <b-td class="text-center">{{ moduleDesignDatasheet | formatAttribute('nameplateImp', 'A') }}</b-td>
            </b-tr>
            <b-tr>
              <b-td>Open circuit voltage (Voc)</b-td>
              <b-td class="text-center">{{ moduleDesignDatasheet | formatAttribute('nameplateVoc', 'V') }}</b-td>
            </b-tr>
            <b-tr>
              <b-td>Short circuit current (Isc)</b-td>
              <b-td class="text-center">{{ moduleDesignDatasheet | formatAttribute('nameplateIsc', 'A') }}</b-td>
            </b-tr>
          </b-tbody>
          <b-thead>
            <b-tr>
              <b-th colspan="2">NOCT</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td>Maximum power voltage (Vpmax)</b-td>
              <b-td class="text-center">{{ moduleDesignDatasheet | formatAttribute('noctVmp', 'V') }}</b-td>
            </b-tr>
            <b-tr>
              <b-td>Maximum power current (Ipmax)</b-td>
              <b-td class="text-center">{{ moduleDesignDatasheet | formatAttribute('noctImp', 'A') }}</b-td>
            </b-tr>
          </b-tbody>
          <b-thead>
            <b-tr>
              <b-th colspan="2">PTC</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td>Module Power (Pmax)</b-td>
              <b-td class="text-center">{{ moduleDesignDatasheet | formatAttribute('ptcPower', 'Wp') }}</b-td>
            </b-tr>
          </b-tbody>
          <b-thead>
            <b-tr>
              <b-th colspan="2">Low Irradiance</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td>Maximum power voltage (Vpmax)</b-td>
              <b-td class="text-center">{{ moduleDesignDatasheet | formatAttribute('lowIrradianceVmp', 'V') }}</b-td>
            </b-tr>
            <b-tr>
              <b-td>Maximum power current (Ipmax)</b-td>
              <b-td class="text-center">{{ moduleDesignDatasheet | formatAttribute('lowIrradianceImp', 'A') }}</b-td>
            </b-tr>
          </b-tbody>
          <b-thead>
            <b-tr>
              <b-th colspan="2">Properties</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td>R<sub>series</sub></b-td>
              <b-td class="text-center">{{ moduleDesignDatasheet | formatAttribute('seriesResistance', 'Ω') }}</b-td>
            </b-tr>
            <b-tr>
              <b-td>R<sub>shunt</sub></b-td>
              <b-td class="text-center">{{ moduleDesignDatasheet | formatAttribute('shuntResistance', 'Ω') }}</b-td>
            </b-tr>
            <b-tr>
              <b-td>Ideality Factor</b-td>
              <b-td class="text-center">{{ moduleDesignDatasheet | formatAttribute('idealityFactor') }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-tab>

      <b-tab title="Temperature Ratings">
        <b-table-simple small hover striped class="mb-0 table-small-text">
          <b-thead>
            <b-tr>
              <b-th colspan="2">Ratings</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td>Temperature Coefficient Isc</b-td>
              <b-td class="text-center">{{ moduleDesignDatasheet | formatAttribute('thermalCoefficientCurrent', '%/°C') }}</b-td>
            </b-tr>
            <b-tr>
              <b-td>Temperature Coefficient Voc</b-td>
              <b-td class="text-center">{{ moduleDesignDatasheet | formatAttribute('thermalCoefficientVoltage', '%/°C') }}</b-td>
            </b-tr>
            <b-tr>
              <b-td>Temperature Coefficient Pmax</b-td>
              <b-td class="text-center">{{ moduleDesignDatasheet | formatAttribute('thermalCoefficientPower', '%/°C') }}</b-td>
            </b-tr>
            <b-tr>
              <b-td>NOCT Temperature</b-td>
              <b-td class="text-center">{{ moduleDesignDatasheet | formatAttribute('noctTemperature', '°C') }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-tab>
    </b-tabs>
    <div class="d-flex justify-content-center align-items-center pt-2" v-if="requestInProgress">
      <msi-spinner :size="32" />
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import { BRow, BCol, BTabs, BTab, BTableSimple, BThead, BTr, BTh, BTbody, BTd } from 'bootstrap-vue';

import MsiSpinner from '../MsiSpinner.vue';

export default {
  name: 'ModuleDesignDatasheet',
  components: {
    MsiSpinner,
    BRow,
    BCol,
    BTabs,
    BTab,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd
  },
  props: {
    moduleDesign: Object
  },
  data() {
    return {
      moduleDesignDatasheet: {},
      requestInProgress: false
    };
  },
  computed: {
    selectedSite: get('sites/selectedSite')
  },
  filters: {
    formatAttribute(value, key, unit) {
      let attribute = value[key];
      if (attribute != null && attribute !== '') {
        if (key === 'template') attribute = attribute ? 'No' : 'Yes';
        if (unit) return `${attribute} ${unit}`;
        return attribute;
      }

      return 'Not Defined';
    }
  },
  watch: {
    moduleDesign: {
      immediate: true,
      async handler() {
        this.moduleDesignDatasheet = {};
        if (!this.selectedSite) return;

        try {
          this.requestInProgress = true;
          if (this.moduleDesign && this.moduleDesign.template) {
            this.moduleDesignDatasheet = await this.$daqApi.get(`/template-module-designs/${this.moduleDesign.uuid}`);
            this.moduleDesignDatasheet.template = true;
          } else if (this.moduleDesign && !this.moduleDesign.template) {
            this.moduleDesignDatasheet = await this.$daqApi.get(`/sites/${this.selectedSite.id}/module-designs/${this.moduleDesign.uuid}`);
            this.moduleDesignDatasheet.template = false;
          }
        } catch (e) {
          if (e.name === 'ApiError') this.$toastError(`Error ${e.status || ''}`, e.message);
          else throw e;
        } finally {
          this.requestInProgress = false;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.datasheet-title {
  font-size: 0.95rem;
  font-weight: 500;
}

.table-small-text {
  font-size: 0.8rem;
}

.datasheet-subtitle {
  font-weight: 500;
}

.datasheet-info {
  font-size: 0.8rem;
}
</style>
