<template>
  <div>
    <b-form-group label-for="module-name-input" label="Solar Module Name">
      <b-form-input id="module-name-input" v-model="name" trim placeholder="Solar Module Name" :disabled="requestInProgress" />
    </b-form-group>

    <b-form-group label-for="module-serial-input" label="Serial Number">
      <b-form-input id="module-serial-input" v-model="serialNumber" trim placeholder="Serial Number" :disabled="requestInProgress" />
    </b-form-group>

    <b-form-group label-for="module-design-input" label="Module Design">
      <multiselect id="module-design-input" v-model="moduleDesign" :options="moduleDesignOptions" track-by="uuid"
        label="name" placeholder="Select Module Design" selectLabel="" deselectLabel="Remove" closeOnSelect
        :maxHeight="600" :disabled="requestInProgress" @input="handleModuleDesignInput">

        <template slot="option" slot-scope="props">
          <div v-if="props.option.action" class="add-module-design"><font-awesome-icon class="mr-2" icon="plus" />{{ props.option.name }}</div>
          <div v-else>{{ props.option.name }}</div>
        </template>
      </multiselect>
    </b-form-group>

    <div class="d-flex pb-2" @click="handleViewDatasheet" v-if="this.moduleDesign && !this.moduleDesign.action">
      <div class="view-datasheet d-flex align-items-center">
        <font-awesome-icon icon='table' />
        <div class="ml-1">View Datasheet</div>
      </div>
    </div>

    <b-form-group label-for="warranty-start-date-input" label="Warranty Start Date">
      <flat-pickr id="warranty-start-date-input" class="msi-date" v-model="warrantyStartDate"
        placeholder="Warranty Start Date" :config="dateConfig" :disabled="requestInProgress" />
    </b-form-group>

    <b-form-group label-for="module-coords-input">
      <template slot="label">
        Coordinates
        <span v-if="supportsClipboard" v-b-tooltip.hover title="Paste Coordinates">
          <font-awesome-icon class="ml-2 button-icon" :icon="['far', 'clipboard']" @click="handlePaste" />
        </span>
      </template>

      <div id="module-coords-input" class="d-flex flex-row justify-content-evenly">
        <b-form-input id="module-latitude-input" class="mx-1 coord-input" v-model="latitude" trim placeholder="Latitude"
          type="number" min="-90" max="90" no-wheel :disabled="requestInProgress" />
        <b-form-input id="module-longitude-input" class="mx-1 coord-input" v-model="longitude" trim placeholder="Longitude"
          type="number" min="-180" max="180" no-wheel :disabled="requestInProgress"/>
      </div>
    </b-form-group>

    <b-form-group label-for="module-group-input" label="Group" >
      <multiselect id="module-group-input" v-model="group" :options="groups" placeholder="Select Group" selectLabel=""
        deselectLabel="Remove" track-by="uuid" :group-select="false" label="name" closeOnSelect :maxHeight="600" :disabled="requestInProgress" />
    </b-form-group>

    <b-form-group label-form="module-control-input" label="Set as control module" label-cols="7" label-cols-sm="4">
      <label class="switch switch-primary mt-1">
        <input type="checkbox" class="switch-input" v-model="isControlModule" checked>
        <span class="switch-slider"></span>
      </label>
    </b-form-group>

    <b-form-group label-for="form-collapse">
      <template slot="label">
        <div class="d-flex align-items-center" v-b-toggle.form-collapse >
          Custom Attributes
          <font-awesome-icon icon="caret-right" :class="[collapseOpen ? 'caret-down' : 'caret-right', 'mx-2']" />
        </div>
      </template>
      <b-collapse id="form-collapse" ref="collapse" @show="collapseOpen = true" @hide="collapseOpen = false">
        <key-value-editor v-model="specialAttributes" :existingOptions="existingAttributes" />
      </b-collapse>
    </b-form-group>

    <b-form-group label-for="form-collapse-default-metrics">
      <template slot="label">
        <div class="d-flex align-items-center" v-b-toggle.form-collapse-default-metrics>
          Default Metrics
          <font-awesome-icon icon="caret-right" :class="[defaultMetricsCollapseOpen ? 'caret-down' : 'caret-right', 'mx-2']" />
        </div>
      </template>
      <b-collapse id="form-collapse-default-metrics" @show="defaultMetricsCollapseOpen = true" @hide="defaultMetricsCollapseOpen = false">
        <default-metrics v-model="defaultMetrics" :inheritedMetrics="inheritedDefaultMetrics" :metrics="metrics" />
      </b-collapse>
    </b-form-group>

    <b-button v-if="!requestInProgress" variant="primary" block :disabled="buttonDisabled" @click="modalOkFunction">{{modalOkText}}</b-button>
    <div class="d-flex justify-content-center"><msi-spinner class="no-select" :size="30" v-if="requestInProgress" /></div>

    <b-modal id="new-module-design-modal" title="New Module Design" hide-footer scrollable>
      <edit-module-type
        modalId="new-module-design-modal"
        :groupedTemplateModuleDesigns="groupedTemplateModuleDesigns"
        :moduleDesigns="moduleDesigns"
        :moduleDesign="newModuleDesign"
        :isEdit="false"
        @reload="reloadModuleDesigns"
        @view-datasheet="handleViewDatasheetNewModuleDesign"
        slot="default" />
    </b-modal>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { get } from 'vuex-pathify';
import moment from 'moment-timezone';
import flatPickr from 'vue-flatpickr-component';
import { BFormGroup, BFormInput, BCollapse, BButton, BModal } from 'bootstrap-vue';

import EditModuleType from './EditModuleType.vue';
import MsiSpinner from '../MsiSpinner.vue';
import KeyValueEditor from '../KeyValueEditor.vue';
import DefaultMetrics from './DefaultMetrics.vue';
import store from '../../store';

export default {
  name: 'EditModule',
  components: {
    Multiselect,
    MsiSpinner,
    KeyValueEditor,
    EditModuleType,
    flatPickr,
    BFormGroup,
    BFormInput,
    BCollapse,
    BButton,
    BModal,
    DefaultMetrics
  },
  props: {
    moduleDesigns: {
      type: Array
    },
    groups: {
      type: Array
    },
    solarModule: {
      type: Object,
    },
    isEdit: {
      type: Boolean,
    },
    modalId: {
      type: String,
    },
    existingAttributes: {
      type: Object,
    }
  },
  data() {
    return {
      name: this.solarModule.name,
      moduleDesign: this.solarModule.moduleDesign && this.moduleDesigns.find(md => md.uuid === this.solarModule.moduleDesign.uuid),
      warrantyStartDate: null,
      group: this.groups.find(group => group.uuid === this.solarModule.groupUuid),
      latitude: this.solarModule.coords && this.solarModule.coords.lat,
      longitude: this.solarModule.coords && this.solarModule.coords.lng,
      serialNumber: this.solarModule.serialNumber,
      isControlModule: this.solarModule.isControlModule,
      requestInProgress: false,
      supportsClipboard: false,
      collapseOpen: false,
      defaultMetricsCollapseOpen: false,
      specialAttributes: this.solarModule.attributes || {},
      defaultMetrics: this.solarModule.ownDefaultMetrics || {},
      newModuleDesign: {}
    };
  },
  computed: {
    selectedSite: get('sites/selectedSite'),
    templateModuleDesigns: get('moduleDesigns/templateModuleDesigns'),
    getSiteMetrics: get('metrics/getSiteMetrics'),
    metrics() {
      if (!this.selectedSite) return [];
      return this.getSiteMetrics(this.selectedSite.id).map((m) => {
        if (this.solarModule && this.solarModule.uuid && m.config && m.config.moduleUuid && this.solarModule.uuid === m.config.moduleUuid) {
          return { ...m, invalid: true };
        }

        return m;
      });
    },
    groupedTemplateModuleDesigns() {
      const designs = {};
      this.templateModuleDesigns.forEach((design) => {
        if (!designs[design.manufacturer]) designs[design.manufacturer] = [design];
        else designs[design.manufacturer].push(design);
      });

      return designs;
    },
    moduleDesignOptions() {
      const addModuleDesignAction = { uuid: 'add-module-design', name: 'Add New Module Design', action: true };
      return [addModuleDesignAction, ...this.moduleDesigns];
    },
    coordinatesValid() {
      if (!this.latitude && !this.longitude) return true;
      return this.latitude && this.longitude;
    },
    moduleDesignValid() {
      return this.moduleDesign && !this.moduleDesign.action;
    },
    formValid() {
      return this.name && this.moduleDesignValid && this.coordinatesValid && this.serialNumber;
    },
    buttonDisabled() {
      if (this.requestInProgress) return true;
      if (!this.formValid) return true;
      return false;
    },
    modalOkText() {
      if (this.isEdit) return 'Update';
      return 'Create';
    },
    modalOkFunction() {
      return this.isEdit ? this.editSolarModule : this.createSolarModule;
    },
    dateConfig() {
      return {
        dateFormat: 'Y-m-d H:i:S',
        disableMobile: true,
        allowInput: true,
        enableTime: true,
        enableSeconds: true
      };
    },
    inheritedDefaultMetrics() {
      let siteDefaultMetrics = {};
      if (this.selectedSite && this.selectedSite.ownDefaultMetrics) {
        siteDefaultMetrics = Object.keys(this.selectedSite.ownDefaultMetrics).reduce((acc, cur) => {
          if (!this.defaultMetrics[cur]) {
            acc[cur] = { metricUuid: this.selectedSite.ownDefaultMetrics[cur], inherited: 'site' };
            if (this.metrics.find(m => m.uuid === this.selectedSite.ownDefaultMetrics[cur] && m.invalid)) {
              acc[cur].invalid = true;
            }
          }
          return acc;
        }, {});
      }

      let groupDefaultMetrics = {};
      if (this.group && this.group.ownDefaultMetrics) {
        groupDefaultMetrics = Object.keys(this.group.ownDefaultMetrics).reduce((acc, cur) => {
          if (!this.defaultMetrics[cur]) {
            acc[cur] = { metricUuid: this.group.ownDefaultMetrics[cur], inherited: 'group' };
            if (this.metrics.find(m => m.uuid === this.group.ownDefaultMetrics[cur] && m.invalid)) {
              acc[cur].invalid = true;
            }
          }
          return acc;
        }, {});
      }

      return { ...siteDefaultMetrics, ...groupDefaultMetrics };
    }
  },
  methods: {
    handleModuleDesignInput(value) {
      if (value && value.action) {
        this.moduleDesign = null;
        document.activeElement.blur();
        this.$bvModal.show('new-module-design-modal');
      }
    },
    async createSolarModule() {
      if (!this.selectedSite || !this.formValid) return;
      this.requestInProgress = true;

      try {
        const body = {
          name: this.name,
          moduleDesignUuid: this.moduleDesign.uuid,
          serialNumber: this.serialNumber,
          groupUuid: this.group && this.group.uuid,
          isControlModule: this.isControlModule,
          attributes: this.specialAttributes,
          defaultMetrics: this.defaultMetrics,
          warrantyStartDate: !this.warrantyStartDate ?
            null : moment.tz(moment(this.warrantyStartDate).format('YYYY-MM-DD HH:mm:ss'), this.selectedSite.timezone).toISOString(),
          coords: null
        };

        if (this.latitude && this.longitude) body.coords = { lat: Number.parseFloat(this.latitude), lng: Number.parseFloat(this.longitude) };
        const response = await this.$daqApi.post(`/sites/${this.selectedSite.id}/modules`, { body });
        this.$toastSuccess('Successful', response.message);
        this.$gtagPlugin.events.Module.new();
        this.$emit('reload');
        this.$bvModal.hide(this.modalId);
      } catch (e) {
        if (e.name === 'ApiError') this.$toastError(`Error ${e.status || ''}`, e.message);
        else throw e;
      } finally {
        this.requestInProgress = false;
      }
    },
    async editSolarModule() {
      if (!this.selectedSite || !this.formValid || !this.solarModule) return;
      this.requestInProgress = true;

      try {
        const body = {
          name: this.name,
          moduleDesignUuid: this.moduleDesign.uuid,
          serialNumber: this.serialNumber,
          groupUuid: this.group && this.group.uuid,
          isControlModule: this.isControlModule,
          attributes: this.specialAttributes,
          defaultMetrics: this.defaultMetrics,
          warrantyStartDate: !this.warrantyStartDate ?
            null : moment.tz(moment(this.warrantyStartDate).format('YYYY-MM-DD HH:mm:ss'), this.selectedSite.timezone).toISOString(),
          coords: null
        };

        if (this.latitude && this.longitude) body.coords = { lat: Number.parseFloat(this.latitude), lng: Number.parseFloat(this.longitude) };
        const response = await this.$daqApi.put(`/sites/${this.selectedSite.id}/modules/${this.solarModule.uuid}`, { body });
        this.$toastSuccess('Successful', response.message);
        this.$gtagPlugin.events.Module.edit({ event_label: this.solarModule.uuid });
        this.$emit('reload');
        this.$bvModal.hide(this.modalId);
      } catch (e) {
        if (e.name === 'ApiError') this.$toastError(`Error ${e.status || ''}`, e.message);
        else throw e;
      } finally {
        this.requestInProgress = false;
      }
    },
    async handlePaste() {
      if (!this.supportsClipboard) return;
      const coordText = await navigator.clipboard.readText();
      try {
        let { lat, lng } = coordText.match(/(?<lat>-?\d+(\.\d+)?), (?<lng>-?\d+(\.\d+)?)/).groups;
        lat = Number.parseFloat(lat);
        lng = Number.parseFloat(lng);
        if (lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180) {
          this.latitude = `${lat}`;
          this.longitude = `${lng}`;
        } else {
          this.$toastError('Error', 'Invalid coordinates in clipboard');
        }
      } catch (e) {
        this.$toastError('Error', 'Invalid coordinates in clipboard');
      }
    },
    reloadModuleDesigns() {
      store.set('moduleDesigns/moduleDesigns');
    },
    handleViewDatasheet() {
      if (this.moduleDesign) this.$emit('view-datasheet', { ...this.moduleDesign, template: false });
    },
    handleViewDatasheetNewModuleDesign(moduleDesign) {
      if (moduleDesign) this.$emit('view-datasheet', moduleDesign);
    }
  },
  created() {
    if (navigator.clipboard) {
      this.supportsClipboard = true;
    }

    if (!this.$store.get('moduleDesigns/templateModuleDesigns').length) {
      this.$store.set('moduleDesigns/templateModuleDesigns');
    }
  },
  watch: {
    selectedSite: {
      immediate: true,
      handler(site) {
        if (site && site.timezone) {
          if (this.solarModule.warrantyStartDate) {
            this.warrantyStartDate = moment.utc(this.solarModule.warrantyStartDate).tz(site.timezone).format('YYYY-MM-DD HH:mm:ss');
          }
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.button-icon {
  cursor: pointer;
  transition: all .1s ease-in-out;
}

.button-icon:hover {
  transform: scale(1.05);
}

.button-icon:active {
  transform: scale(0.9);
}

.caret-right {
  transform: rotate(0deg);
  transition: transform 0.3s linear;
}

.caret-down {
  transform: rotate(90deg);
  transition: transform 0.3s linear;
}

.coord-input::-webkit-outer-spin-button,
.coord-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.coord-input[type=number] {
  -moz-appearance: textfield;
}

.view-datasheet {
  color: $msi-orange;
  cursor: pointer;
  font-weight: 400;
}

.view-datasheet:hover {
  color: $msi-orange-dark;
}

.add-module-design {
  font-size: 0.85rem;
}
</style>
