<template>
  <div class="module-group-form">
    <b-form-group label-for="group-name-input" label="Group Name" >
      <b-form-input id="group-name-input" v-model="name" trim placeholder="Group Name" :disabled="requestInProgress" autofocus/>
    </b-form-group>

    <b-form-group label-for="form-collapse-default-metrics">
      <template slot="label">
        <div class="d-flex align-items-center" v-b-toggle.form-collapse-default-metrics>
          Default Metrics
          <font-awesome-icon icon="caret-right" :class="[defaultMetricsCollapseOpen ? 'caret-down' : 'caret-right', 'mx-2']" />
        </div>
      </template>
      <b-collapse id="form-collapse-default-metrics" @show="defaultMetricsCollapseOpen = true" @hide="defaultMetricsCollapseOpen = false">
        <default-metrics v-model="defaultMetrics" :inheritedMetrics="inheritedDefaultMetrics" :metrics="metrics" />
      </b-collapse>
    </b-form-group>

    <b-button v-if="!requestInProgress" variant="primary" block :disabled="buttonDisabled"
    @click="modalOkFunction">{{modalOkText}}</b-button>
    <div class="d-flex justify-content-center"><msi-spinner class="no-select" :size="30" v-if="requestInProgress" /></div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import { BFormGroup, BFormInput, BButton, BCollapse } from 'bootstrap-vue';

import MsiSpinner from '../MsiSpinner.vue';
import DefaultMetrics from './DefaultMetrics.vue';

export default {
  name: 'EditModuleGroup',
  components: {
    MsiSpinner,
    BFormGroup,
    BFormInput,
    BButton,
    BCollapse,
    DefaultMetrics
  },
  props: {
    moduleGroup: {
      type: Object,
    },
    isEdit: {
      type: Boolean,
    },
    modalId: {
      type: String,
    }
  },
  data() {
    const { name, ownDefaultMetrics: defaultMetrics } = this.moduleGroup;
    return {
      name,
      defaultMetrics: defaultMetrics || {},
      requestInProgress: false,
      defaultMetricsCollapseOpen: false
    };
  },
  computed: {
    selectedSite: get('sites/selectedSite'),
    getSiteMetrics: get('metrics/getSiteMetrics'),
    metrics() {
      if (!this.selectedSite) return [];
      return this.getSiteMetrics(this.selectedSite.id);
    },
    buttonDisabled() {
      if (this.requestInProgress) return true;
      if (!this.name) return true;
      return false;
    },
    modalOkText() {
      if (this.isEdit) return 'Update';
      return 'Create';
    },
    modalOkFunction() {
      return this.isEdit ? this.editGroup : this.createGroup;
    },
    inheritedDefaultMetrics() {
      let siteDefaultMetrics = {};
      if (this.selectedSite && this.selectedSite.ownDefaultMetrics) {
        siteDefaultMetrics = Object.keys(this.selectedSite.ownDefaultMetrics).reduce((acc, cur) => {
          if (!this.defaultMetrics[cur]) acc[cur] = { metricUuid: this.selectedSite.ownDefaultMetrics[cur], inherited: 'site' };
          return acc;
        }, {});
      }

      return siteDefaultMetrics;
    }
  },
  methods: {
    async createGroup() {
      if (!this.name || !this.selectedSite) return;
      this.requestInProgress = true;

      try {
        const body = { name: this.name, defaultMetrics: this.defaultMetrics };
        const response = await this.$daqApi.post(`/sites/${this.selectedSite.id}/groups`, { body });
        this.$toastSuccess('Successful', response.message);
        this.$gtagPlugin.events.ModuleGroup.new();
        this.$emit('reload');
        this.$bvModal.hide(this.modalId);
      } catch (e) {
        if (e.name === 'ApiError') this.$toastError(`Error ${e.status || ''}`, e.message);
        else throw e;
      } finally {
        this.requestInProgress = false;
      }
    },
    async editGroup() {
      if (!this.name || !this.selectedSite) return;
      this.requestInProgress = true;

      try {
        const body = { name: this.name, defaultMetrics: this.defaultMetrics };
        const response = await this.$daqApi.put(`/sites/${this.selectedSite.id}/groups/${this.moduleGroup.uuid}`, { body });
        this.$toastSuccess('Successful', response.message);
        this.$gtagPlugin.events.ModuleGroup.edit({ event_label: this.moduleGroup.uuid });
        this.$emit('reload');
        this.$bvModal.hide(this.modalId);
      } catch (e) {
        if (e.name === 'ApiError') this.$toastError(`Error ${e.status || ''}`, e.message);
        else throw e;
      } finally {
        this.requestInProgress = false;
      }
    },
  }
};
</script>

<style scoped>
.module-group-form {
  width: 100%;
}
</style>
