const defaultNumberValidatorOptions = {
  required: false,
  integer: false,
  min: undefined,
  max: undefined,
  exclusiveMin: undefined,
  exclusiveMax: undefined
};

export function validateNumber(value, label, options = defaultNumberValidatorOptions) {
  const { required, integer, min, max, exclusiveMin, exclusiveMax } = options;
  if (!label) label = '';
  if (label.trim()) label = `${label.trim()} `;
  if (required && (value == null || (typeof value === 'string' && value.trim() === ''))) {
    return { valid: false, message: label ? `${label}is required` : 'required' };
  }

  if (value == null || (typeof value === 'string' && value.trim() === '')) return { valid: true };

  value = Number.parseFloat(value);
  if (integer && !Number.isInteger(value)) return { valid: false, message: `${label}must be an integer` };

  let message = `${label}must be`;
  let minFail = false;
  let maxFail = false;
  if (exclusiveMin != null && value <= exclusiveMin) {
    minFail = true;
    message = `${message} greater than ${exclusiveMin}`;
  } else if (min != null && value < min) {
    minFail = true;
    message = `${message} equal/greater than ${min}`;
  }

  if (exclusiveMax != null && value >= exclusiveMax) {
    maxFail = true;
    if (minFail) message = `${message} and less than ${exclusiveMax}`;
    else message = `${message} less than ${exclusiveMax}`;
  } else if (max != null && value > max) {
    maxFail = true;
    if (minFail) message = `${message} and equal/less than ${max}`;
    else message = `${message} equal/less than ${max}`;
  }

  if (minFail || maxFail) return { valid: false, message };
  return { valid: true };
}

export const transformers = {
  methods: {
    transformString(s) {
      if (s == null || typeof s !== 'string') return null;
      const parsedString = s.trim();
      if (parsedString.length === 0) return null;
      return parsedString;
    },
    transformNumber(n) {
      if (n == null) return null;
      const parsedNumber = Number.parseFloat(n);
      if (Number.isNaN(parsedNumber)) return null;
      return parsedNumber;
    }
  }
};
