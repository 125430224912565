<template>
<div>
  <h1 class="display-4 sensor-link-header">Module Designs</h1>
  <b-row class="my-3 m-0" no-gutters align-h="between" align-v="center">
    <b-col lg="6" class="d-flex my-1">
      <b-button size="sm" variant="primary" class="flex-grow-1 flex-sm-grow-0" @click="handleCreateClick"
      :disabled="requestInProgress || loadingSites">Create New Design</b-button>
    </b-col>
    <b-col lg="6" class="d-flex my-1">
      <b-input-group size="sm">
        <b-form-input
          v-model="filter"
          type="search"
          placeholder="Type to Search"
        ></b-form-input>
        <b-input-group-append>
          <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
        </b-input-group-append>
      </b-input-group>
    </b-col>
  </b-row>
  <div class="d-flex flex-column justify-content-between flex-grow-1">
    <b-table
      id="module-types-tables"
      :items="moduleDesigns"
      :fields="tableFields"
      primaryKey="uuid"
      :show-empty="!requestInProgress && !loadingSites"
      :busy="requestInProgress || loadingSites"
      emptyText="No module designs available"
      selectMode="range"
      :per-page="perPage"
      :current-page="currentPage"
      hover
      striped
      sort-icon-left
      responsive
      stacked="sm"
      :filter="filter"
      @filtered="onFiltered"
      :filter-function="filterMethod"
    >
      <template v-slot:head(datasheet)="scope">
        <div class="text-center">{{ scope.label }}</div>
      </template>

      <template v-slot:cell(datasheet)="{ item }">
        <div class="view-datasheet text-center" @click="handleViewDatasheet({ ...item, template: false })">View</div>
      </template>
      <template v-slot:cell(configure)="{ item, toggleDetails }">
        <div class="d-inline-flex justify-content-end align-items-center w-100 pr-0 pr-sm-1 pr-md-2 pr-lg-3">
          <b-button size="sm" variant="primary" class="mr-2" aria-label="configure module design" @click="() => handleEditClick(item)">
            <font-awesome-icon icon='wrench' />
          </b-button>
          <div
            class="mr-2"
            tabindex="0"
            v-b-tooltip.hover.top
            :title="!Object.keys(item.attributes || {}).length ? 'There are no custom attributes for this design.' : ''">
            <b-button aria-label="show custom attributes" size="sm" variant="info"
            @click="toggleDetails" :disabled="!Object.keys(item.attributes || {}).length">
              <font-awesome-icon icon='info-circle' />
            </b-button>
          </div>
          <b-button v-if="canDelete" aria-label="delete module design" size="sm" variant="danger" @click="() => handleDeleteClick(item)">
            <font-awesome-icon icon='trash-alt' />
          </b-button>
        </div>
      </template>
      <template v-slot:row-details="{ item }">
        <b-table
          :items="mapDataToRow(item)"
          :fields="['name', 'value']"
          striped
          outlined
          responsive
          stacked="sm"
          small
          hover
          fixed
          table-variant="light"
          head-variant="light"
        />
      </template>
    </b-table>
    <div class="w-100 d-flex justify-content-center">
      <msi-spinner class="no-select" :size="60" v-if="requestInProgress || loadingSites"/>
    </div>
    <div class="d-flex justify-content-center mt-2 w-100">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        aria-controls="module-types-table"
        class="m-0"
      ></b-pagination>
    </div>
  </div>

  <b-modal id="module-design-modal" :title="modalTitleText" hide-footer scrollable>
    <edit-module-type
      modalId="module-design-modal"
      :groupedTemplateModuleDesigns="groupedTemplateModuleDesigns"
      :moduleDesigns="moduleDesigns"
      :moduleDesign="editingDesign"
      :isEdit="isEdit"
      @reload="reload"
      @view-datasheet="handleViewDatasheet"
      :existingAttributes="existingCustomAttributes"
      slot="default"/>
  </b-modal>

  <b-modal
    ref="deleteModal"
    title="Delete Confirmation"
    ok-title="Delete"
    ok-variant="danger"
    :ok-disabled="isDeleting"
    @ok.prevent="executeDelete"
    @hidden="deleteModuleDesign = null"
    size="sm">
    <div v-if="deleteModuleDesign">
      <p>Delete the module design <b>{{deleteModuleDesign.name}}</b>?</p>
      <span style="color: #ff6961;">
        This action is <b>not</b> reversible.
        <msi-spinner :size="28" v-if="isDeleting" />
      </span>
    </div>
  </b-modal>
</div>
</template>

<script>
import { get } from 'vuex-pathify';
import { BRow, BCol, BButton, BInputGroup, BFormInput, BInputGroupAppend, BTable, BPagination, BModal } from 'bootstrap-vue';

import EditModuleType from './EditModuleType.vue';
import MsiSpinner from '../MsiSpinner.vue';
import store from '../../store';

export default {
  name: 'ModuleTypeList',
  components: {
    EditModuleType,
    MsiSpinner,
    BRow,
    BCol,
    BButton,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BTable,
    BPagination,
    BModal
  },
  data() {
    return {
      requestInProgress: false,
      editingDesign: null,
      isEdit: false,
      tableFields: [
        { key: 'name', sortable: true },
        { key: 'width', sortable: true, label: 'Width (m)' },
        { key: 'length', sortable: true, label: 'Length (m)' },
        { key: 'datasheet', label: 'Datasheet' },
        { key: 'configure', label: '' }
      ],
      perPage: 12,
      currentPage: 1,
      totalRows: 1,
      filter: '',
      deleteModuleDesign: null,
      isDeleting: false,
    };
  },
  computed: {
    selectedSite: get('sites/selectedSite'),
    getSiteModuleDesigns: get('moduleDesigns/getSiteModuleDesigns'),
    templateModuleDesigns: get('moduleDesigns/templateModuleDesigns'),
    loadingSites: get('sites/loadingSites'),
    moduleDesigns() {
      if (!this.selectedSite) return [];
      return this.getSiteModuleDesigns(this.selectedSite.id).map(md => ({ ...md }));
    },
    groupedTemplateModuleDesigns() {
      const designs = {};
      this.templateModuleDesigns.forEach((design) => {
        if (!designs[design.manufacturer]) designs[design.manufacturer] = [design];
        else designs[design.manufacturer].push(design);
      });

      return designs;
    },
    modalTitleText() {
      if (this.isEdit) return 'Module Design Configuration';
      return 'New Module Design';
    },
    canDelete() {
      if (!this.selectedSite) return false;
      return this.$kauth.hasSiteRole(this.selectedSite.id, 'operator');
    },
    existingCustomAttributes() {
      const existingAttributes = {};
      this.moduleDesigns.forEach(({ attributes }) => {
        if (!attributes) return;
        Object.entries(attributes).forEach(([key, value]) => {
          if (!existingAttributes[key]) existingAttributes[key] = new Set();
          existingAttributes[key].add(value);
        });
      });
      return existingAttributes;
    }
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    handleEditClick(moduleDesign) {
      this.editingDesign = moduleDesign;
      this.isEdit = true;
      this.$bvModal.show('module-design-modal');
    },
    handleCreateClick() {
      this.editingDesign = { name: null, npr: null, width: null, length: null };
      this.isEdit = false;
      this.$bvModal.show('module-design-modal');
    },
    handleDeleteClick(sModule) {
      this.deleteModuleDesign = sModule;
      this.$refs.deleteModal.show();
    },
    async executeDelete() {
      if (!this.selectedSite || !this.deleteModuleDesign) return;
      if (!this.canDelete) {
        this.$toastError('Error', 'Insufficient permissions for site');
        return;
      }

      this.requestInProgress = true;
      this.isDeleting = true;
      const { uuid } = this.deleteModuleDesign;
      try {
        const response = await this.$daqApi.delete(`/sites/${this.selectedSite.id}/module-designs/${uuid}`);
        this.$gtagPlugin.events.ModuleDesign.delete({ event_label: uuid });
        this.$toastSuccess('Successful', response.message);
        this.reload();
      } catch (e) {
        if (e.name === 'ApiError') this.$toastError(`Error ${e.status || ''}`, e.message);
        else throw e;
      } finally {
        this.requestInProgress = false;
        this.isDeleting = false;
        this.$refs.deleteModal.hide();
      }
    },
    async reload() {
      store.set('moduleDesigns/moduleDesigns');
    },
    mapDataToRow(data) {
      return Object.entries(data.attributes).map(([name, value]) => ({ name, value }));
    },
    filterMethod(design, filterString) {
      const parts = filterString.split(/ +/g).map(s => s.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'));
      const regex = RegExp(parts.join('.*'), 'i');
      const params = [design.name, design.length, design.width]
        .map(value => `${value}`);
      return params.some(s => s.match(regex));
    },
    handleViewDatasheet(moduleDesign) {
      if (moduleDesign) this.$emit('view-datasheet', moduleDesign);
    }
  },
  created() {
    if (!this.$store.get('moduleDesigns/templateModuleDesigns').length) {
      this.$store.set('moduleDesigns/templateModuleDesigns');
    }
  }
};
</script>

<style lang="scss" scoped>
  .sensor-config-container {
    height: calc(100vh - 105px)
  }

  .min-height-fit {
    min-height: fit-content;
  }

  .sensor-link-header {
    font-size: 1.8rem;
  }

  .view-datasheet {
    color: $msi-orange;
    cursor: pointer;
    font-weight: 500;
  }

  .view-datasheet:hover {
    color:$msi-orange-dark;
  }
</style>
