<template>
  <div>
    <h1 class="display-4 sensor-link-header">Module Groups</h1>
    <b-row class="my-3 m-0" no-gutters align-h="between" align-v="center">
      <b-col lg="6" class="d-flex my-1">
        <b-button size="sm" variant="primary" class="flex-grow-1 flex-sm-grow-0" @click="handleCreateClick"
        :disabled="requestInProgress || loadingSites">Create New Group</b-button>
      </b-col>
      <b-col lg="6" class="d-flex justify-content-center my-1">
        <b-input-group size="sm">
          <b-form-input
            v-model="filter"
            type="search"
            placeholder="Type to Search"
          ></b-form-input>
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <div class="d-flex flex-column justify-content-between flex-grow-1">
      <b-table
        id="module-group-table"
        :items="moduleGroups"
        :fields="tableFields"
        primaryKey="uuid"
        :show-empty="!requestInProgress && !loadingSites"
        :busy="requestInProgress || loadingSites"
        emptyText="No module groups available"
        :per-page="perPage"
        :current-page="currentPage"
        hover
        striped
        sort-icon-left
        responsive
        stacked="sm"
        :filter="filter"
        @filtered="onFiltered"
        :filter-function="filterMethod">
        <template v-slot:cell(configure)="{ item }">
          <div class="d-inline-flex justify-content-end align-items-center w-100 pr-0 pr-sm-1 pr-md-2 pr-lg-3">
            <b-button aria-label="configure module group" size="sm" variant="primary" class="mr-2" @click="() => handleEditClick(item)">
              <font-awesome-icon icon='wrench' />
            </b-button>
            <b-button v-if="canDelete" aria-label="delete module group" size="sm" variant="danger" @click="() => handleDeleteClick(item)">
              <font-awesome-icon icon='trash-alt' />
            </b-button>
          </div>
        </template>
      </b-table>
      <div class="w-100 d-flex justify-content-center">
        <msi-spinner class="no-select" :size="60" v-if="requestInProgress || loadingSites"/>
      </div>
      <div class="d-flex justify-content-center mt-2 w-100">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          :limit="$feature.mobile ? 5 : 8"
          aria-controls="module-types-table"
          class="m-0"
        />
      </div>
    </div>

    <b-modal id="module-group-modal" :title="modalTitleText" hide-footer>
      <edit-module-group
        modalId="module-group-modal"
        :moduleGroup="editingGroup"
        :isEdit="isEdit"
        @reload="() => reloadGroups(selectedSite)"
        slot="default"/>
    </b-modal>

    <b-modal
      ref="deleteModal"
      title="Delete Confirmation"
      ok-title="Delete"
      ok-variant="danger"
      :ok-disabled="isDeleting"
      @ok.prevent="executeDelete"
      @hidden="deleteModuleGroup = null"
      size="sm">
      <div v-if="deleteModuleGroup">
        <p>Delete the module group <b>{{deleteModuleGroup.name}}</b>?</p>
        <span style="color: #ff6961;">
          This action is <b>not</b> reversible.
          <msi-spinner :size="28" v-if="isDeleting" />
        </span>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import { BRow, BCol, BButton, BInputGroup, BFormInput, BInputGroupAppend, BTable, BPagination, BModal } from 'bootstrap-vue';

import EditModuleGroup from './EditModuleGroup.vue';
import MsiSpinner from '../MsiSpinner.vue';
import store from '../../store';

export default {
  name: 'ModuleGroupList',
  components: {
    EditModuleGroup,
    MsiSpinner,
    BRow,
    BCol,
    BButton,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BTable,
    BPagination,
    BModal
  },
  data() {
    return {
      requestInProgress: false,
      editingGroup: null,
      isEdit: false,
      tableFields: [
        { key: 'name', sortable: true },
        { key: 'configure', label: '' }
      ],
      perPage: 12,
      currentPage: 1,
      totalRows: 1,
      filter: '',
      deleteModuleGroup: null,
      isDeleting: false,
    };
  },
  computed: {
    selectedSite: get('sites/selectedSite'),
    getSiteModuleGroups: get('groups/getSiteGroups'),
    loadingSites: get('sites/loadingSites'),
    moduleGroups() {
      if (!this.selectedSite) return [];
      return this.getSiteModuleGroups(this.selectedSite.id);
    },
    modalTitleText() {
      if (this.isEdit) return 'Module Group Configuration';
      return 'New Module Group';
    },
    canDelete() {
      if (!this.selectedSite) return false;
      return this.$kauth.hasSiteRole(this.selectedSite.id, 'operator');
    }
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    handleEditClick(moduleGroup) {
      this.editingGroup = moduleGroup;
      this.isEdit = true;
      this.$bvModal.show('module-group-modal');
    },
    handleCreateClick() {
      this.editingGroup = { name: '' };
      this.isEdit = false;
      this.$bvModal.show('module-group-modal');
    },
    handleDeleteClick(group) {
      this.deleteModuleGroup = group;
      this.$refs.deleteModal.show();
    },
    async executeDelete() {
      if (!this.deleteModuleGroup) return;
      if (!this.canDelete) {
        this.$toastError('Error', 'Insufficient permissions for site');
        return;
      }
      this.isDeleting = true;
      const { uuid } = this.deleteModuleGroup;
      try {
        const response = await this.$daqApi.delete(`/sites/${this.selectedSite.id}/groups/${uuid}`);
        this.$toastSuccess('Successful', response.message);
        this.$gtagPlugin.events.ModuleGroup.delete({ event_label: uuid });
        this.reloadGroups(this.selectedSite);
      } catch (e) {
        if (e.name === 'ApiError') this.$toastError(`Error ${e.status || ''}`, e.message);
        else throw e;
      } finally {
        this.isDeleting = false;
        this.$refs.deleteModal.hide();
      }
    },
    async reloadGroups(site) {
      if (!site) return;
      store.set('solarmodules/solarModules');
      store.set('groups/groups');
    },
    filterMethod(group, filterString) {
      const parts = filterString.split(/ +/g).map(s => s.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'));
      const regex = RegExp(parts.join('.*'), 'i');
      const params = [group.name]
        .map(value => `${value}`);
      return params.some(s => s.match(regex));
    }
  },
  watch: {
    moduleGroups: {
      immediate: true,
      handler(moduleGroups) {
        this.totalRows = moduleGroups.length;
      },
    }
  }
};
</script>

<style scoped>
  .sensor-config-container {
    height: calc(100vh - 105px)
  }

  .min-height-fit {
    min-height: fit-content;
  }

  .sensor-link-header {
    font-size: 1.8rem;
  }
</style>
