<template>
  <div class="module-design-form">
    <b-card body-class="p-3" class="mb-3" v-if="!isEdit">
      <div class="pb-2 d-flex align-items-center flex-wrap flex-sm-nowrap">
        <div class="flex-grow-1">Fill fields from existing module design</div>
        <msi-spinner class="no-select" :size="24" v-if="requestInProgress && fillInProgress" />
        <b-button variant="primary" class="mt-2 mt-sm-0 ml-1" size="sm" :disabled="!fillFormValid"
        @click="handleFillForm">
          Fill Fields
        </b-button>
      </div>
      <multiselect v-model="manufacturer" :options="manufacturers" placeholder="Manufacturer" selectLabel="" deselectLabel="Remove"
        closeOnSelect :maxHeight="600" :disabled="requestInProgress" class="pb-2" />
      <multiselect v-model="model" :options="manufacturerModels" placeholder="Model" selectLabel="" deselectLabel="Remove"
        track-by="uuid" :label="manufacturer === 'User Created Module Designs' ? 'name' : 'modelNumber'"
        closeOnSelect :maxHeight="600" :disabled="requestInProgress" />
      <div class="d-flex pt-2" @click="handleViewDatasheet" v-if="this.model">
        <div class="view-datasheet d-flex align-items-center">
          <font-awesome-icon icon='table' />
          <div class="ml-1">View Datasheet</div>
        </div>
      </div>
    </b-card>

    <div class="module-design-title pb-2">Module Design</div>

    <b-form-group label-for="basic-information-collapse" class="ml-1 mb-2">
      <template slot="label">
        <div class="d-flex">
          <div class="d-flex align-items-center form-toggler" v-b-toggle.basic-information-collapse>
            Basic Information
            <font-awesome-icon icon="caret-right" :class="[basicInformationCollapseOpen ? 'caret-down' : 'caret-right', 'mx-2']" />
          </div>
        </div>
      </template>
      <b-collapse id="basic-information-collapse" accordion="design-accordion" visible @show="basicInformationCollapseOpen = true"
        @hide="basicInformationCollapseOpen = false">
        <basic-information v-model="basicInformation" :disabled="requestInProgress" :moduleDesigns="moduleDesigns" :moduleDesign="moduleDesign"
          @valid="(valid) => handleFromValidity('basic-information', valid)" />
      </b-collapse>
    </b-form-group>

    <b-form-group label-for="recommended-fields-collapse" class="ml-1 mb-2">
      <template slot="label">
        <div class="d-flex">
          <div class="d-flex align-items-center flex-wrap form-toggler" v-b-toggle.recommended-fields-collapse>
            Recommended Fields
            <font-awesome-icon icon="caret-right" :class="[recommendedFieldsCollapseOpen ? 'caret-down' : 'caret-right', 'mx-2']" />
            <div class="collapse-message">(Required for some features)</div>
          </div>
        </div>
      </template>
      <b-collapse id="recommended-fields-collapse" accordion="design-accordion" @show="recommendedFieldsCollapseOpen = true"
        @hide="recommendedFieldsCollapseOpen = false">
        <recommended-fields v-model="recommendedFields" :disabled="requestInProgress"
          @valid="(valid) => handleFromValidity('recommended-fields', valid)" />
      </b-collapse>
    </b-form-group>

    <b-form-group label-for="optional-fields-collapse" class="ml-1 mb-2">
      <template slot="label">
        <div class="d-flex">
          <div class="d-flex align-items-center form-toggler" v-b-toggle.optional-fields-collapse>
            Optional Fields
            <font-awesome-icon icon="caret-right" :class="[optionalFieldsCollapseOpen ? 'caret-down' : 'caret-right', 'mx-2']" />
          </div>
        </div>
      </template>
      <b-collapse id="optional-fields-collapse" accordion="design-accordion" @show="optionalFieldsCollapseOpen = true"
        @hide="optionalFieldsCollapseOpen = false">
        <optional-fields v-model="optionalFields" :disabled="requestInProgress" @valid="(valid) => handleFromValidity('optional-fields', valid)" />
      </b-collapse>
    </b-form-group>

    <b-form-group label-for="custom-attributes-collapse" class="ml-1">
      <template slot="label">
        <div class="d-flex">
          <div class="d-flex align-items-center form-toggler" v-b-toggle.custom-attributes-collapse>
            Custom Attributes
            <font-awesome-icon icon="caret-right" :class="[customAttributesCollapseOpen ? 'caret-down' : 'caret-right', 'mx-2']" />
          </div>
        </div>
      </template>
      <b-collapse id="custom-attributes-collapse" @show="customAttributesCollapseOpen = true" @hide="customAttributesCollapseOpen = false"
        accordion="design-accordion">
        <key-value-editor
          v-model="specialAttributes"
          emptyText="This design has no custom attributes. Add a new attribute below!"
          :existingOptions="existingAttributes" />
      </b-collapse>
    </b-form-group>

    <b-button v-if="!requestInProgress" variant="primary" block :disabled="!formValid"
    @click="modalOkFunction">{{modalOkText}}</b-button>
    <div class="d-flex justify-content-center"><msi-spinner class="no-select" :size="30" v-if="requestInProgress && !fillInProgress" /></div>
  </div>
</template>

<script>
/* eslint-disable camelcase */
import { get } from 'vuex-pathify';
import isEqual from 'lodash/isEqual';
import Multiselect from 'vue-multiselect';
import { BCard, BButton, BFormGroup, BCollapse } from 'bootstrap-vue';

import MsiSpinner from '../MsiSpinner.vue';
import KeyValueEditor from '../KeyValueEditor.vue';
import BasicInformation from './EditModuleDesignForm/BasicInformation.vue';
import RecommendedFields from './EditModuleDesignForm/RecommendedFields.vue';
import OptionalFields from './EditModuleDesignForm/OptionalFields.vue';

export default {
  name: 'EditModuleDesign',
  components: {
    Multiselect,
    MsiSpinner,
    KeyValueEditor,
    BasicInformation,
    RecommendedFields,
    OptionalFields,
    BCard,
    BButton,
    BFormGroup,
    BCollapse
  },
  props: {
    moduleDesign: {
      type: Object
    },
    moduleDesigns: {
      type: Array
    },
    groupedTemplateModuleDesigns: {
      type: Object
    },
    existingAttributes: {
      type: Object
    },
    isEdit: {
      type: Boolean
    },
    modalId: {
      type: String
    }
  },
  data() {
    return {
      manufacturer: null,
      model: null,
      basicInformation: {},
      recommendedFields: {},
      optionalFields: {},
      specialAttributes: this.moduleDesign.attributes || {},
      basicInformationCollapseOpen: true,
      recommendedFieldsCollapseOpen: false,
      optionalFieldsCollapseOpen: false,
      customAttributesCollapseOpen: false,
      requestInProgress: false,
      fillInProgress: false,
      basicInformationValid: false,
      recommendedFieldsValid: false,
      optionalFieldsValid: false
    };
  },
  computed: {
    selectedSite: get('sites/selectedSite'),
    manufacturers() {
      const manufacturers = Object.keys(this.groupedTemplateModuleDesigns);
      if (this.moduleDesigns.length > 0) return ['User Created Module Designs', ...manufacturers];
      return manufacturers;
    },
    manufacturerModels() {
      if (this.manufacturer === 'User Created Module Designs') return this.moduleDesigns;
      return this.groupedTemplateModuleDesigns[this.manufacturer] || [];
    },
    moduleDesignClone() {
      return { ...this.basicInformation, ...this.recommendedFields, ...this.optionalFields };
    },
    formValid() {
      if (this.requestInProgress) return false;
      if (!this.basicInformationValid || !this.recommendedFieldsValid || !this.optionalFieldsValid) return false;
      return this.designChanged;
    },
    modalOkText() {
      if (this.isEdit) return 'Update';
      return 'Create';
    },
    modalOkFunction() {
      return this.isEdit ? this.editDesign : this.createDesign;
    },
    designChanged() {
      const cloneKeys = Object.keys(this.moduleDesignClone);
      const changed = cloneKeys.some((key) => {
        if (this.moduleDesignClone[key] !== this.moduleDesign[key]) return true;
        return false;
      });

      if (changed) return true;
      return !isEqual(this.specialAttributes, this.moduleDesign.attributes || {});
    },
    fillFormValid() {
      if (!this.manufacturer || !this.model || this.requestInProgress) return false;
      return true;
    }
  },
  methods: {
    async createDesign() {
      if (!this.selectedSite || !this.formValid) return;
      this.requestInProgress = true;

      const body = { ...this.moduleDesignClone };
      if (this.specialAttributes) body.attributes = { ...this.specialAttributes };

      try {
        const response = await this.$daqApi.post(`/sites/${this.selectedSite.id}/module-designs`, { body });
        this.$toastSuccess('Successful', response.message);
        this.$gtagPlugin.events.ModuleDesign.new();
        this.$emit('reload');
        this.$bvModal.hide(this.modalId);
      } catch (e) {
        if (e.name === 'ApiError') this.$toastError(`Error ${e.status || ''}`, e.message);
        else throw e;
      } finally {
        this.requestInProgress = false;
      }
    },
    async editDesign() {
      if (!this.selectedSite || !this.formValid || !this.moduleDesign.uuid) return;
      this.requestInProgress = true;

      const body = { ...this.moduleDesignClone };
      if (this.specialAttributes) body.attributes = { ...this.specialAttributes };

      try {
        const response = await this.$daqApi.put(`/sites/${this.selectedSite.id}/module-designs/${this.moduleDesign.uuid}`, { body });
        this.$toastSuccess('Successful', response.message);
        this.$gtagPlugin.events.ModuleDesign.edit({ event_label: this.moduleDesign.uuid });
        this.$emit('reload');
        this.$bvModal.hide(this.modalId);
      } catch (e) {
        if (e.name === 'ApiError') this.$toastError(`Error ${e.status || ''}`, e.message);
        else throw e;
      } finally {
        this.requestInProgress = false;
      }
    },
    async handleFillForm() {
      if (!this.selectedSite || !this.fillFormValid) return;

      let moduleDesign;
      try {
        this.requestInProgress = true;
        this.fillInProgress = true;

        if (this.manufacturer === 'User Created Module Designs') {
          moduleDesign = await this.$daqApi.get(`/sites/${this.selectedSite.id}/module-designs/${this.model.uuid}`);
        } else {
          moduleDesign = await this.$daqApi.get(`/template-module-designs/${this.model.uuid}`);
        }

        this.extractForms(moduleDesign);
      } catch (e) {
        if (e.name === 'ApiError') this.$toastError(`Error ${e.status || ''}`, e.message);
        else throw e;
      } finally {
        this.requestInProgress = false;
        this.fillInProgress = false;
      }
    },
    extractForms(design) {
      const moduleDesign = JSON.parse(JSON.stringify(design));
      const { name, width, length, numberCellsSeries, nameplateStcPower, nameplateVoc, nameplateIsc, nameplateVmp, nameplateImp,
        thermalCoefficientVoltage, thermalCoefficientCurrent, thermalCoefficientPower, warranty,
        manufacturer, modelNumber, description, family, technology, area, isBipv, ptcPower, numberCellsParallel, noctTemperature,
        lowIrradianceImp, lowIrradianceVmp, noctImp, noctVmp, cellTempExpr } = moduleDesign;

      this.basicInformation = { name, width, length, manufacturer, modelNumber, description, family, technology, area, isBipv };
      this.optionalFields = { ptcPower, numberCellsParallel, noctTemperature, lowIrradianceImp, lowIrradianceVmp, noctImp, noctVmp, cellTempExpr };
      this.recommendedFields = {
        numberCellsSeries,
        nameplateStcPower,
        nameplateVoc,
        nameplateIsc,
        nameplateVmp,
        nameplateImp,
        thermalCoefficientVoltage,
        thermalCoefficientCurrent,
        thermalCoefficientPower,
        warranty
      };
    },
    resetForms() {
      this.basicInformation = {};
      this.recommendedFields = {};
      this.optionalFields = {};
    },
    handleFromValidity(form, valid) {
      if (form === 'basic-information') this.basicInformationValid = valid;
      else if (form === 'recommended-fields') this.recommendedFieldsValid = valid;
      else if (form === 'optional-fields') this.optionalFieldsValid = valid;
    },
    handleViewDatasheet() {
      if (this.manufacturer && this.model) {
        if (this.manufacturer === 'User Created Module Designs') this.$emit('view-datasheet', { ...this.model, template: false });
        else this.$emit('view-datasheet', { ...this.model, template: true });
      }
    }
  },
  watch: {
    moduleDesign: {
      immediate: true,
      async handler() {
        if (this.moduleDesign) {
          this.extractForms(this.moduleDesign);
          if (this.isEdit && this.selectedSite) {
            try {
              this.requestInProgress = true;
              const fullModuleDesign = await this.$daqApi.get(`/sites/${this.selectedSite.id}/module-designs/${this.moduleDesign.uuid}`);
              this.extractForms(fullModuleDesign);
            } catch (e) {
              if (e.name === 'ApiError') this.$toastError(`Error ${e.status || ''}`, e.message);
              else throw e;
            } finally {
              this.requestInProgress = false;
            }
          }
        } else this.resetForms();
      }
    },
    manufacturer() {
      this.model = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.module-design-title {
  font-weight: 500;
  font-size: 1.1rem;
}

.module-design-form {
  width: 100%;
}

.caret-right {
  transform: rotate(0deg);
  transition: transform 0.3s linear;
}

.caret-down {
  transform: rotate(90deg);
  transition: transform 0.3s linear;
}

.collapse-message {
  font-size: 0.75rem;
}

.form-toggler {
  cursor: pointer;
}

.view-datasheet {
  color: $msi-orange;
  cursor: pointer;
  font-weight: 400;
}

.view-datasheet:hover {
  color: $msi-orange-dark;
}
</style>
