<template>
  <div>
    <div class="d-flex justify-content-center pb-1 text-muted info-text" v-if="!warranty.length">
      No Warranty Spans
    </div>

    <b-row v-for="(warranty, index) in warranty" :key="index" class="pb-3">
      <b-col cols="12" sm="4">
        <b-form-group
          class="mb-0"
          :invalid-feedback="validation[index].span.message"
          :state="validation[index].span.valid ? null : false">
          <b-input-group append="years" size="sm">
            <b-form-input
              type="number"
              size="sm"
              v-model.number="warranty.span"
              placeholder="Span"
              @input="handleInput"
              :state="validation[index].span.valid ? null : false" />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" sm="4">
        <b-form-group
          class="mb-0"
          :invalid-feedback="validation[index].start.message"
          :state="validation[index].start.valid ? null : false">
          <b-input-group append="%" size="sm">
            <b-form-input
              type="number"
              size="sm"
              v-model.number="warranty.start"
              placeholder="Start"
              @input="handleInput"
              :state="validation[index].start.valid ? null : false" />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" sm="4">
        <b-form-group
          class="mb-0"
          :invalid-feedback="validation[index].end.message"
          :state="validation[index].end.valid ? null : false">
          <b-input-group append="%" size="sm">
            <b-form-input
              type="number"
              size="sm"
              v-model.number="warranty.end"
              placeholder="End"
              @input="handleInput"
              :state="validation[index].end.valid ? null : false" />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-button variant="primary" size="sm" @click="addSpan" class="mr-2">Add</b-button>
    <b-button variant="danger" size="sm" @click="removeSpan" v-if="warranty.length">Remove</b-button>
  </div>
</template>

<script>
import { BFormInput, BCol, BRow, BButton, BFormGroup, BInputGroup } from 'bootstrap-vue';
import { validateNumber } from './formUtils';

export default {
  name: 'WarrantyForm',
  components: {
    BFormInput,
    BCol,
    BRow,
    BButton,
    BFormGroup,
    BInputGroup
  },
  props: {
    value: Array
  },
  data() {
    return {
      warranty: this.value ? JSON.parse(JSON.stringify(this.value)) : []
    };
  },
  computed: {
    validation() {
      return this.warranty.map((w) => {
        return {
          span: validateNumber(w.span, null, { exclusiveMin: 0, required: true }),
          start: validateNumber(w.start, null, { min: 0, max: 100, required: true }),
          end: validateNumber(w.end, null, { min: 0, max: 100, required: true }),
        };
      });
    },
    valid() {
      return !this.validation.some(v => Object.keys(v).some(k => !v[k].valid));
    }
  },
  methods: {
    addSpan() {
      this.warranty.push({ span: null, start: null, end: null });
    },
    removeSpan() {
      this.warranty.pop();
    },
    handleInput() {
      this.$emit('input', this.warranty);
    }
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.warranty = this.value ? JSON.parse(JSON.stringify(this.value)) : [];
      }
    },
    valid: {
      immediate: true,
      handler() {
        this.$emit('valid', this.valid);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.info-text {
  font-size: 0.8rem;
}
</style>
