<template>
  <b-row class="module-design-basic-information">
    <b-col cols="12">
      <b-form-group
        label-for="design-name-input"
        label="Design Name"
        class="required"
        :invalid-feedback="validation.name.message"
        :state="validation.name.valid ? null : false">
        <b-form-input
          id="design-name-input"
          :value="local.name"
          placeholder="Design Name"
          @input="(val) => update('name', transformString(val))"
          :disabled="disabled"
          :state="validation.name.valid ? null : false" />
      </b-form-group>
    </b-col>

    <b-col cols="12" sm="6">
      <b-form-group
        label-for="design-width-input"
        label="Width"
        class="required"
        :invalid-feedback="validation.width.message"
        :state="validation.width.valid ? null : false">
        <b-input-group append="m">
          <b-form-input
            id="design-width-input"
            :value="local.width"
            placeholder="Width"
            type="number"
            @input="(val) => update('width', transformNumber(val))"
            :disabled="disabled"
            :state="validation.width.valid ? null : false" />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12" sm="6">
      <b-form-group
        label-for="design-length-input"
        label="Length"
        class="required"
        :invalid-feedback="validation.length.message"
        :state="validation.length.valid ? null : false">
        <b-input-group append="m">
          <b-form-input
            id="design-length-input"
            :value="local.length"
            placeholder="Length"
            type="number"
            @input="(val) => update('length', transformNumber(val))"
            :disabled="disabled"
            :state="validation.length.valid ? null : false" />
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="12" sm="6">
      <b-form-group
        label-for="design-manufacturer-input"
        label="Manufacturer">
        <b-form-input
          id="design-manufacturer-input"
          :value="local.manufacturer"
          placeholder="Manufacturer"
          @input="(val) => update('manufacturer', transformString(val))"
          :disabled="disabled" />
      </b-form-group>
    </b-col>
    <b-col cols="12" sm="6">
      <b-form-group
        label-for="design-modelNumber-input"
        label="Model">
        <b-form-input
          id="design-modelNumber-input"
          :value="local.modelNumber"
          placeholder="Model"
          @input="(val) => update('modelNumber', transformString(val))"
          :disabled="disabled" />
      </b-form-group>
    </b-col>

    <b-col cols="12" sm="6">
      <b-form-group
        label-for="design-family-input"
        label="Family">
        <b-form-select
          id="design-family-input"
          :value="local.family || null"
          :options="familyOptions"
          @input="(val) => update('family', val)"
          :disabled="disabled" />
      </b-form-group>
    </b-col>
    <b-col cols="12" sm="6">
      <b-form-group
        label-for="design-technology-input"
        label="Technology">
          <b-form-select
            id="design-technology-input"
            :value="local.technology || null"
            :options="technologyOptions"
            @input="(val) => update('technology', val)"
            :disabled="disabled" />
      </b-form-group>
    </b-col>

    <b-col cols="12" sm="6">
      <b-form-group
        label-for="design-area-input"
        label="Area"
        class="mb-2"
        :invalid-feedback="validation.area.message"
        :state="validation.area.valid ? null : false">
        <b-input-group append="m²">
          <b-form-input
            id="design-area-input"
            :value="local.area"
            placeholder="Area"
            type="number"
            @input="(val) => update('area', transformNumber(val))"
            :disabled="disabled"
            :state="validation.area.valid ? null : false" />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12" sm="6">
      <b-form-group
        label-for="design-isBipv-input"
        label="Is it a BIPV?"
        class="mb-2">
        <label class="switch switch-primary mt-1">
          <input type="checkbox" class="switch-input" :checked="local.isBipv" @input="update('isBipv', $event.target.checked)" :disabled="disabled">
          <span class="switch-slider"></span>
        </label>
      </b-form-group>
    </b-col>

    <b-col>
      <b-form-group
        label-for="design-description-input"
        label="Description" class="mb-0">
        <b-form-textarea
          id="design-description-input"
          :value="local.description"
          placeholder="Description"
          rows="2"
          no-resize
          max-rows="2"
          @input="(val) => update('description', transformString(val))"
          :disabled="disabled" />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormInput, BFormSelect, BFormTextarea, BInputGroup } from 'bootstrap-vue';

import { validateNumber, transformers } from './formUtils';

export default {
  name: 'BasicInformation',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BInputGroup
  },
  mixins: [transformers],
  props: {
    value: Object,
    moduleDesigns: {
      type: Array,
      default() {
        return [];
      }
    },
    moduleDesign: Object,
    disabled: Boolean
  },
  data() {
    return {
      familyOptions: [{ value: null, text: 'None' }, 'CIGS Thin Film', 'HIT-Si Thin Film', 'Monocrystalline', 'Polycrystalline', 'Thin Film'],
      technologyOptions: [{ value: null, text: 'None' }, 'CdTe', 'CIGS', 'Mono-c-Si', 'Multi-c-Si', 'Thin Film']
    };
  },
  computed: {
    local() {
      return this.value ? this.value : {};
    },
    validation() {
      return {
        name: this.validateName(),
        width: validateNumber(this.local.width, 'Width', { required: true, exclusiveMin: 0 }),
        length: validateNumber(this.local.length, 'Length', { required: true, exclusiveMin: 0 }),
        area: validateNumber(this.local.area, 'Area', { exclusiveMin: 0 }),
      };
    },
    valid() {
      return !Object.values(this.validation).some(v => !v.valid);
    }
  },
  methods: {
    update(key, value) {
      this.$emit('input', { ...this.local, [key]: value });
    },
    validateName() {
      if (!this.local.name) return { valid: false, message: 'Design name is required.' };
      const exists = this.moduleDesigns.find(model => model.name === this.local.name.trim());
      if (exists && this.moduleDesign && this.moduleDesign.uuid != null && this.moduleDesign.uuid === exists.uuid) return { valid: true };
      if (exists) return { valid: false, message: 'Design name already exists.' };
      return { valid: true };
    }
  },
  watch: {
    valid: {
      immediate: true,
      handler() {
        this.$emit('valid', this.valid);
      }
    }
  }
};
</script>

<style lang="scss">
.module-design-basic-information .form-group.required label::after {
  content: '*';
  font-size: 0.85rem;
  margin-left: 0.25rem;
  color: $msi-orange;
}
</style>
