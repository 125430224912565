<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-form-group
          label-for="design-numberCellsParallel-input"
          label="Number of Cells (Parallel)"
          :invalid-feedback="validation.numberCellsParallel.message"
          :state="validation.numberCellsParallel.valid ? null : false">
          <b-form-input
            id="design-numberCellsParallel-input"
            :value="local.numberCellsParallel"
            placeholder="Number of Cells (Parallel)"
            type="number"
            @input="(val) => update('numberCellsParallel', transformNumber(val))"
            :disabled="disabled"
            :state="validation.numberCellsParallel.valid ? null : false" />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          label-for="design-ptcPower-input"
          label="PTC Power">
          <b-input-group append="W">
            <b-form-input
              id="design-ptcPower-input"
              :value="local.ptcPower"
              placeholder="PTC Power"
              type="number"
              @input="(val) => update('ptcPower', transformNumber(val))"
              :disabled="disabled" />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          label-for="design-cellTempExpr-input">
          <template #label>
            <div class="d-flex align-items-center">
              <div class="mr-1">Cell Temperature Expression</div>
              <info-icon size="1x" class="mt-1" tooltipClass="cellTempExpr-info-tooltip">
                <div class="pb-2">
                  A mathematical expression that evaluates to a numerical value. You can use the following parameters in the expression:
                </div>
                <div>
                  <b>IV Parameters:</b> Isc, Voc, Imp, Pmp, Vmp, Temperature
                </div>
                <div>
                  <b>Ref Cell Parameters:</b> Irr, CellTemp
                </div>
                <div>
                  <b>Module Design Parameters:</b> numberCellsSeries, thermalCoefficientCurrent, thermalCoefficientVoltage, nameplateVoc,
                  shuntResistance, seriesResistance
                </div>
                <div class="pt-2">The default expression is <b>Temperature</b>.</div>
                <div class="pt-2">Examples: <b>Temperature + 0.5</b>, <b>Temperature + (Irr / 1000)</b></div>
                <div class="pt-1">
                  Refer to these <a href="https://mathjs.org/docs/reference/functions.html" target="_blank">docs</a> for more math functions.
                </div>
              </info-icon>
            </div>
          </template>
          <b-input-group>
            <b-form-input
              id="design-cellTempExpr-input"
              :value="local.cellTempExpr"
              placeholder="Cell Temperature Expression"
              @input="(val) => update('cellTempExpr', transformString(val))"
              :disabled="disabled" />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <fieldset class="p-2 form-col mb-3">
      <legend class="px-2">NOCT</legend>
      <b-row>
        <b-col cols="12" sm="6">
          <b-form-group
            label-for="design-noctImp-input"
            label="Imp">
            <b-input-group append="A">
              <b-form-input
                id="design-noctImp-input"
                :value="local.noctImp"
                placeholder="Imp"
                type="number"
                @input="(val) => update('noctImp', transformNumber(val))"
                :disabled="disabled" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="6">
          <b-form-group
            label-for="design-noctVmp-input"
            label="Vmp">
            <b-input-group append="V">
              <b-form-input
                id="design-noctVmp-input"
                :value="local.noctVmp"
                placeholder="Vmp"
                type="number"
                @input="(val) => update('noctVmp', transformNumber(val))"
                :disabled="disabled" />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12" sm="6">
          <b-form-group
            label-for="design-noctTemperature-input"
            label="Temperature">
            <b-input-group append="°C">
              <b-form-input
                id="design-noctTemperature-input"
                :value="local.noctTemperature"
                placeholder="Temperature"
                type="number"
                @input="(val) => update('noctTemperature', transformNumber(val))"
                :disabled="disabled" />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </fieldset>

    <fieldset class="p-2 form-col mb-3">
      <legend class="px-2">Low Irradiance</legend>
      <b-row>
        <b-col cols="12" sm="6">
          <b-form-group
            label-for="design-lowIrradianceImp-input"
            label="Imp">
            <b-input-group append="A">
              <b-form-input
                id="design-lowIrradianceImp-input"
                :value="local.lowIrradianceImp"
                placeholder="Imp"
                type="number"
                @input="(val) => update('lowIrradianceImp', transformNumber(val))"
                :disabled="disabled" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="6">
          <b-form-group
            label-for="design-lowIrradianceVmp-input"
            label="Vmp">
            <b-input-group append="V">
              <b-form-input
                id="design-lowIrradianceVmp-input"
                :value="local.lowIrradianceVmp"
                placeholder="Vmp"
                type="number"
                @input="(val) => update('lowIrradianceVmp', transformNumber(val))"
                :disabled="disabled" />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </fieldset>
  </div>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormInput, BInputGroup } from 'bootstrap-vue';
import InfoIcon from '../../ui/InfoIcon.vue';
import { validateNumber, transformers } from './formUtils';

export default {
  name: 'OptionalFields',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    InfoIcon
  },
  mixins: [transformers],
  props: {
    value: Object,
    disabled: Boolean
  },
  computed: {
    local() {
      return this.value ? this.value : {};
    },
    validation() {
      return {
        numberCellsParallel: validateNumber(this.local.numberCellsParallel, 'Number of Cells (Parallel)', { integer: true, min: 0 })
      };
    },
    valid() {
      return !Object.values(this.validation).some(v => !v.valid);
    }
  },
  methods: {
    update(key, value) {
      this.$emit('input', { ...this.local, [key]: value });
    }
  },
  watch: {
    valid: {
      immediate: true,
      handler() {
        this.$emit('valid', this.valid);
      }
    }
  }
};
</script>

<style scoped>
fieldset.form-col {
  border: thin solid rgb(233, 236, 239);
  border-radius: 5px;
}

fieldset.form-col legend {
  font-size: 0.8em;
  padding-right: 1px;
  padding-left: 1px;
  width: auto;
  margin: 0;
  color: rgb(195, 195, 195);
}
</style>

<style>
.cellTempExpr-info-tooltip .tooltip-inner {
  text-align: left;
}
</style>
